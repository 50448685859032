// The components within this file are shared by the accessioning (process TRF) and medical billing (updating billing info) teams.
import {
	useState,
	Fragment,
	Dispatch,
	useEffect,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	FormGroup,
	DateField,
	SelectField,
	GenericField,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Assay,
	Option,
	ClinicData,
	REQUISITION_FORM,
} from '@pangea-lis-apps/utils';

import FlagButton from '../../../../../../../components/flag-button';

export interface BillingInfoFormValues {
	[key: string]:
		| string
		| boolean
		| { [key: string]: boolean | undefined }
		| undefined;
	billing_verified: string;
	billing_method: string;
	billing_method_other: string;
	// patient
	billing_patient_payment_received: string;
	billing_patient_payment_payee: string;
	// client
	billing_client_existing_client_billing_method: string;
	billing_client_client_name: string;
	billing_client_client_institution: string;
	billing_client_billing_contact_name: string;
	billing_client_billing_contact_institution: string;
	billing_client_billing_contact_address: string;
	billing_client_billing_contact_address2: string;
	billing_client_billing_contact_city: string;
	billing_client_billing_contact_state: string;
	billing_client_billing_contact_zip_code: string;
	billing_client_billing_contact_country: string;
	billing_client_billing_contact_phone_number: string;
	billing_client_billing_contact_fax_number: string;
	billing_client_billing_contact_email_address: string;
	// primary insurance
	billing_primary_insurance_insurance_type: string;
	billing_primary_insurance_subscriber_first_name: string;
	billing_primary_insurance_subscriber_last_name: string;
	billing_primary_insurance_subscriber_date_of_birth: string;
	billing_primary_insurance_patient_relationship_to_subscriber: string;
	billing_primary_insurance_patient_relationship_to_subscriber_other: string;
	billing_primary_insurance_subscriber_phone_number: string;
	billing_primary_insurance_subscriber_group_number: string;
	billing_primary_insurance_subscriber_policy_id_number: string;
	billing_primary_insurance_company_name: string;
	billing_primary_insurance_company_address: string;
	billing_primary_insurance_company_city: string;
	billing_primary_insurance_company_state: string;
	billing_primary_insurance_company_zip_code: string;
	billing_primary_insurance_company_country: string;
	billing_has_secondary_insurance: string;
	// secondary insurance
	billing_secondary_insurance_insurance_type: string;
	billing_secondary_insurance_subscriber_first_name: string;
	billing_secondary_insurance_subscriber_last_name: string;
	billing_secondary_insurance_subscriber_date_of_birth: string;
	billing_secondary_insurance_patient_relationship_to_subscriber: string;
	billing_secondary_insurance_patient_relationship_to_subscriber_other: string;
	billing_secondary_insurance_subscriber_phone_number: string;
	billing_secondary_insurance_subscriber_policy_id_number: string;
	billing_secondary_insurance_subscriber_group_number: string;
	billing_secondary_insurance_company_name: string;
	billing_secondary_insurance_company_address: string;
	billing_secondary_insurance_company_city: string;
	billing_secondary_insurance_company_state: string;
	billing_secondary_insurance_company_zip_code: string;
	billing_secondary_insurance_company_country: string;
	flag: BaseFlagFormValues;
}

interface BaseFlagFormValues {
	[key: string]: boolean | undefined;
	billing_verified: boolean;
	billing_method: boolean;
	billing_method_other: boolean;
	// patient
	billing_patient_payment_received: boolean;
	billing_patient_payment_payee: boolean;
	// client
	billing_client_existing_client_billing_method: boolean;
	billing_client_client_name: boolean;
	billing_client_client_institution: boolean;
	billing_client_billing_contact_name: boolean;
	billing_client_billing_contact_institution: boolean;
	billing_client_billing_contact_address: boolean;
	billing_client_billing_contact_address2: boolean;
	billing_client_billing_contact_city: boolean;
	billing_client_billing_contact_state: boolean;
	billing_client_billing_contact_zip_code: boolean;
	billing_client_billing_contact_country: boolean;
	billing_client_billing_contact_phone_number: boolean;
	billing_client_billing_contact_fax_number: boolean;
	billing_client_billing_contact_email_address: boolean;
	// primary insurance
	billing_primary_insurance_insurance_type: boolean;
	billing_primary_insurance_subscriber_first_name: boolean;
	billing_primary_insurance_subscriber_last_name: boolean;
	billing_primary_insurance_subscriber_date_of_birth: boolean;
	billing_primary_insurance_patient_relationship_to_subscriber: boolean;
	billing_primary_insurance_patient_relationship_to_subscriber_other: boolean;
	billing_primary_insurance_subscriber_phone_number: boolean;
	billing_primary_insurance_subscriber_group_number: boolean;
	billing_primary_insurance_subscriber_policy_id_number: boolean;
	billing_primary_insurance_company_name: boolean;
	billing_primary_insurance_company_address: boolean;
	billing_primary_insurance_company_city: boolean;
	billing_primary_insurance_company_state: boolean;
	billing_primary_insurance_company_zip_code: boolean;
	billing_primary_insurance_company_country: boolean;
	billing_has_secondary_insurance: boolean;
	// secondary insurance
	billing_secondary_insurance_insurance_type: boolean;
	billing_secondary_insurance_subscriber_first_name: boolean;
	billing_secondary_insurance_subscriber_last_name: boolean;
	billing_secondary_insurance_subscriber_date_of_birth: boolean;
	billing_secondary_insurance_patient_relationship_to_subscriber: boolean;
	billing_secondary_insurance_patient_relationship_to_subscriber_other: boolean;
	billing_secondary_insurance_subscriber_phone_number: boolean;
	billing_secondary_insurance_subscriber_policy_id_number: boolean;
	billing_secondary_insurance_subscriber_group_number: boolean;
	billing_secondary_insurance_company_name: boolean;
	billing_secondary_insurance_company_address: boolean;
	billing_secondary_insurance_company_city: boolean;
	billing_secondary_insurance_company_state: boolean;
	billing_secondary_insurance_company_zip_code: boolean;
	billing_secondary_insurance_company_country: boolean;
}

const billingValues = {
	billing_verified: '',
	billing_method: '',
	billing_method_other: '',
	// patient
	billing_patient_payment_received: '',
	billing_patient_payment_payee: '',
	// client
	billing_client_existing_client_billing_method: '',
	billing_client_client_name: '',
	billing_client_client_institution: '',
	billing_client_billing_contact_name: '',
	billing_client_billing_contact_institution: '',
	billing_client_billing_contact_address: '',
	billing_client_billing_contact_address2: '',
	billing_client_billing_contact_city: '',
	billing_client_billing_contact_state: '',
	billing_client_billing_contact_zip_code: '',
	billing_client_billing_contact_country: '',
	billing_client_billing_contact_phone_number: '',
	billing_client_billing_contact_fax_number: '',
	billing_client_billing_contact_email_address: '',
	// primary insurance
	billing_primary_insurance_insurance_type: '',
	billing_primary_insurance_subscriber_first_name: '',
	billing_primary_insurance_subscriber_last_name: '',
	billing_primary_insurance_subscriber_date_of_birth: '',
	billing_primary_insurance_patient_relationship_to_subscriber: '',
	billing_primary_insurance_patient_relationship_to_subscriber_other: '',
	billing_primary_insurance_subscriber_phone_number: '',
	billing_primary_insurance_subscriber_group_number: '',
	billing_primary_insurance_subscriber_policy_id_number: '',
	billing_primary_insurance_company_name: '',
	billing_primary_insurance_company_address: '',
	billing_primary_insurance_company_city: '',
	billing_primary_insurance_company_state: '',
	billing_primary_insurance_company_zip_code: '',
	billing_primary_insurance_company_country: '',
	billing_has_secondary_insurance: '',
	// secondary insurance
	billing_secondary_insurance_insurance_type: '',
	billing_secondary_insurance_subscriber_first_name: '',
	billing_secondary_insurance_subscriber_last_name: '',
	billing_secondary_insurance_subscriber_date_of_birth: '',
	billing_secondary_insurance_patient_relationship_to_subscriber: '',
	billing_secondary_insurance_patient_relationship_to_subscriber_other: '',
	billing_secondary_insurance_subscriber_phone_number: '',
	billing_secondary_insurance_subscriber_policy_id_number: '',
	billing_secondary_insurance_subscriber_group_number: '',
	billing_secondary_insurance_company_name: '',
	billing_secondary_insurance_company_address: '',
	billing_secondary_insurance_company_city: '',
	billing_secondary_insurance_company_state: '',
	billing_secondary_insurance_company_zip_code: '',
	billing_secondary_insurance_company_country: '',
};

const flagValues = {
	billing_verified: false,
	billing_method: false,
	billing_method_other: false,
	// patient
	billing_patient_payment_received: false,
	billing_patient_payment_payee: false,
	// client
	billing_client_existing_client_billing_method: false,
	billing_client_client_name: false,
	billing_client_client_institution: false,
	billing_client_billing_contact_name: false,
	billing_client_billing_contact_institution: false,
	billing_client_billing_contact_address: false,
	billing_client_billing_contact_address2: false,
	billing_client_billing_contact_city: false,
	billing_client_billing_contact_state: false,
	billing_client_billing_contact_zip_code: false,
	billing_client_billing_contact_country: false,
	billing_client_billing_contact_phone_number: false,
	billing_client_billing_contact_fax_number: false,
	billing_client_billing_contact_email_address: false,
	// primary insurance
	billing_primary_insurance_insurance_type: false,
	billing_primary_insurance_subscriber_first_name: false,
	billing_primary_insurance_subscriber_last_name: false,
	billing_primary_insurance_subscriber_date_of_birth: false,
	billing_primary_insurance_patient_relationship_to_subscriber: false,
	billing_primary_insurance_patient_relationship_to_subscriber_other: false,
	billing_primary_insurance_subscriber_phone_number: false,
	billing_primary_insurance_subscriber_group_number: false,
	billing_primary_insurance_subscriber_policy_id_number: false,
	billing_primary_insurance_company_name: false,
	billing_primary_insurance_company_address: false,
	billing_primary_insurance_company_city: false,
	billing_primary_insurance_company_state: false,
	billing_primary_insurance_company_zip_code: false,
	billing_primary_insurance_company_country: false,
	billing_has_secondary_insurance: false,
	// secondary insurance
	billing_secondary_insurance_insurance_type: false,
	billing_secondary_insurance_subscriber_first_name: false,
	billing_secondary_insurance_subscriber_last_name: false,
	billing_secondary_insurance_subscriber_date_of_birth: false,
	billing_secondary_insurance_patient_relationship_to_subscriber: false,
	billing_secondary_insurance_patient_relationship_to_subscriber_other: false,
	billing_secondary_insurance_subscriber_phone_number: false,
	billing_secondary_insurance_subscriber_policy_id_number: false,
	billing_secondary_insurance_subscriber_group_number: false,
	billing_secondary_insurance_company_name: false,
	billing_secondary_insurance_company_address: false,
	billing_secondary_insurance_company_city: false,
	billing_secondary_insurance_company_state: false,
	billing_secondary_insurance_company_zip_code: false,
	billing_secondary_insurance_company_country: false,
};

export const initialSTIBillingInfoFormValues = {
	...billingValues,
	flag: flagValues,
};

export const initialResp4PlexBillingInfoFormValues =
	initialSTIBillingInfoFormValues;

export const initialFITBillingInfoFormValues = initialSTIBillingInfoFormValues;

const initialDropdownSelections = {
	billing_primary_insurance_company_state: '',
	billing_secondary_insurance_company_state: '',
	billing_client_billing_contact_state: '',
	billing_primary_insurance_company_name: '',
	billing_secondary_insurance_company_name: '',
};

export const getInitialBillingInfoFormValues = (assay: Assay) => {
	if (assay === 'sti') {
		return initialSTIBillingInfoFormValues;
	} else if (assay === 'resp4plex') {
		return initialResp4PlexBillingInfoFormValues;
	} else if (assay === 'fit') {
		return initialFITBillingInfoFormValues;
	}

	return null;
};

interface BillingInfoProps {
	data: ClinicData;
	hideFlagButton?: boolean;
	formValues: BillingInfoFormValues;
	setFormValues: Dispatch<SetStateAction<BillingInfoFormValues | undefined>>;
}

export function BillingInfoFields({
	data,
	formValues,
	setFormValues,
	hideFlagButton,
}: BillingInfoProps) {
	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setFormValues((prevValues) => {
				if (prevValues) {
					const value = target.value;

					if (target.name === 'billing_method') {
						setDropdownSelections(initialDropdownSelections);

						return {
							...initialSTIBillingInfoFormValues,
							[target.name]: value,
						};
					} else if (
						target.name ===
							'billing_client_existing_client_billing_method' &&
						data
					) {
						if (target.value) {
							const billing_method =
								data.customer.billing_methods.filter(
									(method) => method._id.$oid === target.value
								)[0];

							return {
								...prevValues,
								[target.name]: target.value,
								billing_client_client_name:
									billing_method.client_name,
								billing_client_client_institution:
									billing_method.client_institution,
								billing_client_billing_contact_name:
									billing_method.billing_contact_name,
								billing_client_billing_contact_institution:
									billing_method.billing_contact_institution,
								billing_client_billing_contact_address:
									billing_method.billing_contact_address,
								billing_client_billing_contact_address2:
									billing_method.billing_contact_address2,
								billing_client_billing_contact_city:
									billing_method.billing_contact_city,
								billing_client_billing_contact_state:
									billing_method.billing_contact_state,
								billing_client_billing_contact_zip_code:
									billing_method.billing_contact_zip_code,
								billing_client_billing_contact_phone_number:
									billing_method.billing_contact_phone_number,
								billing_client_billing_contact_fax_number:
									billing_method.billing_contact_fax_number,
								billing_client_billing_contact_email_address:
									billing_method.billing_contact_email_address,
							};
						}

						return {
							...prevValues,
							[target.name]: target.value,
							billing_client_client_name: '',
							billing_client_client_institution: '',
							billing_client_billing_contact_name: '',
							billing_client_billing_contact_institution: '',
							billing_client_billing_contact_address: '',
							billing_client_billing_contact_address2: '',
							billing_client_billing_contact_city: '',
							billing_client_billing_contact_state: '',
							billing_client_billing_contact_zip_code: '',
							billing_client_billing_contact_phone_number: '',
							billing_client_billing_contact_fax_number: '',
							billing_client_billing_contact_email_address: '',
						};
					}

					return {
						...prevValues,
						[target.name]: value,
					};
				}

				return prevValues;
			});
	};

	const [dropdownSelections, setDropdownSelections] = useState(
		initialDropdownSelections
	);

	useEffect(() => {
		if (formValues) {
			// setting primary insurance company name
			if (!dropdownSelections.billing_primary_insurance_company_name) {
				if (
					!REQUISITION_FORM[
						'shared'
					].billing_common_insurance_companies
						.map((option: Option) => option.value)
						.includes(
							formValues.billing_primary_insurance_company_name
						)
				)
					setDropdownSelections((prevValue) => ({
						...prevValue,
						billing_primary_insurance_company_name: 'other',
					}));
				else
					setDropdownSelections((prevValue) => ({
						...prevValue,
						billing_primary_insurance_company_name:
							formValues.billing_primary_insurance_company_name,
					}));
			}

			// setting secondary insurance company name
			if (!dropdownSelections.billing_secondary_insurance_company_name) {
				if (
					!REQUISITION_FORM[
						'shared'
					].billing_common_insurance_companies
						.map((option: Option) => option.value)
						.includes(
							formValues.billing_secondary_insurance_company_name
						)
				)
					setDropdownSelections((prevValue) => ({
						...prevValue,
						billing_secondary_insurance_company_name: 'other',
					}));
				else
					setDropdownSelections((prevValue) => ({
						...prevValue,
						billing_secondary_insurance_company_name:
							formValues.billing_secondary_insurance_company_name,
					}));
			}

			// billing info - client's billing contact address, state
			if (!dropdownSelections.billing_client_billing_contact_state) {
				if (
					!FORMS.states
						.map((option: Option) => option.value)
						.includes(
							formValues.billing_client_billing_contact_state
						)
				)
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_client_billing_contact_state: 'other',
					}));
				else
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_client_billing_contact_state:
							formValues.billing_client_billing_contact_state,
					}));
			}

			// billing info - primary insurance address, state
			if (!dropdownSelections.billing_primary_insurance_company_state) {
				if (
					!FORMS.states
						.map((option: Option) => option.value)
						.includes(
							formValues.billing_primary_insurance_company_state
						)
				)
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_primary_insurance_company_state: 'other',
					}));
				else
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_primary_insurance_company_state:
							formValues.billing_primary_insurance_company_state,
					}));
			}

			// billing info - secondary insurance address, state
			if (!dropdownSelections.billing_secondary_insurance_company_state) {
				if (
					!FORMS.states
						.map((option: Option) => option.value)
						.includes(
							formValues.billing_secondary_insurance_company_state
						)
				)
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_secondary_insurance_company_state: 'other',
					}));
				else
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_secondary_insurance_company_state:
							formValues.billing_secondary_insurance_company_state,
					}));
			}
		}
	}, [
		formValues,
		dropdownSelections.billing_client_billing_contact_state,
		dropdownSelections.billing_primary_insurance_company_name,
		dropdownSelections.billing_primary_insurance_company_state,
		dropdownSelections.billing_secondary_insurance_company_name,
		dropdownSelections.billing_secondary_insurance_company_state,
	]);

	const handleDropdownSelectionChange: ChangeEventHandler<
		HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name) {
			setDropdownSelections((prevValue) => ({
				...prevValue,
				[target.name]: target.value,
			}));

			setFormValues((prevValues) => {
				if (prevValues) {
					return {
						...prevValues,
						[target.name]:
							target.value === 'other' ? '' : target.value,
					};
				}

				return prevValues;
			});
		}
	};

	return (
		<Fragment>
			<FormGroup heading="Billing information">
				<div className="sm:col-span-2">
					<SelectField
						name="billing_method"
						label="Billing Method"
						showRequiredAsterisk={true}
						value={formValues['billing_method']}
						handleSelect={handleChange}
						options={
							REQUISITION_FORM[data.sample.assay][
								data.requisition_form.metadata.version
							].billing_information_payer_options
						}
						fieldAction={
							!hideFlagButton && (
								<FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="billing_method"
								/>
							)
						}
					/>
				</div>
				{formValues['billing_method'] === 'other' && (
					<div className="sm:col-span-2">
						<GenericField
							required
							type="text"
							showRequiredAsterisk={true}
							placeholder="e.g., Employer"
							label="If other, please specify"
							name="billing_method_other"
							value={formValues['billing_method_other']}
							handleInputChange={handleChange}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_method_other"
									/>
								)
							}
						/>
					</div>
				)}
				<div className="sm:col-span-2">
					<SelectField
						required
						name="billing_verified"
						label="Billing Verified?"
						value={formValues.billing_verified}
						handleSelect={handleChange}
						options={FORMS.yes_no_options}
						fieldAction={
							!hideFlagButton && (
								<FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="billing_verified"
								/>
							)
						}
					/>
				</div>
			</FormGroup>
			{formValues.billing_method === 'client' && (
				<Fragment>
					<FormGroup
						heading="Client information"
						description="Choose from the existing billing methods. New billing methods will automatically be saved to the provider's customer profile. For new billing methods, you must provide the client's name and the billing contact's institution or company name."
					>
						{formValues.billing_method === 'client' && (
							<Fragment>
								<div className="sm:col-span-2">
									<SelectField
										label="Existing client billing methods"
										name="billing_client_existing_client_billing_method"
										value={
											formValues.billing_client_existing_client_billing_method
										}
										handleSelect={handleChange}
										options={(() => {
											if (
												data.customer.billing_methods &&
												data.customer.billing_methods
													.length > 0
											)
												return [
													{
														value: '',
														label: 'Select an Option',
													},
													...data.customer.billing_methods.map(
														(billing_method) => ({
															label:
																billing_method.client_name +
																' - ' +
																billing_method.billing_contact_institution,
															value: billing_method
																._id.$oid,
														})
													),
												];

											return [
												{
													value: '',
													label: 'None available',
												},
											];
										})()}
									/>
								</div>
								<div className="sm:col-span-4"></div>
							</Fragment>
						)}
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Name"
								showRequiredAsterisk={true}
								name="billing_client_client_name"
								placeholder="e.g., George Harman"
								value={formValues['billing_client_client_name']}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_client_name"
										/>
									)
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Institution or Company"
								showRequiredAsterisk={true}
								name="billing_client_client_institution"
								placeholder="e.g., City of Irvine"
								value={
									formValues[
										'billing_client_client_institution'
									]
								}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_client_institution"
										/>
									)
								}
							/>
						</div>
					</FormGroup>

					<FormGroup heading="Client's billing contact information">
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Name"
								name="billing_client_billing_contact_name"
								placeholder="e.g., Alex Weisman"
								value={
									formValues[
										'billing_client_billing_contact_name'
									]
								}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_name"
										/>
									)
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								showRequiredAsterisk={true}
								label="Institution or Company"
								name="billing_client_billing_contact_institution"
								placeholder="e.g., City of Irvine"
								value={
									formValues[
										'billing_client_billing_contact_institution'
									]
								}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_institution"
										/>
									)
								}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-4">
							<GenericField
								type="text"
								label="Address"
								showRequiredAsterisk={true}
								name="billing_client_billing_contact_address"
								placeholder="e.g., 1234 Orange Ave."
								value={
									formValues[
										'billing_client_billing_contact_address'
									]
								}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_address"
										/>
									)
								}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-4">
							<GenericField
								type="text"
								label="Address Line 2"
								name="billing_client_billing_contact_address2"
								placeholder="e.g., Suite 101"
								value={
									formValues[
										'billing_client_billing_contact_address2'
									]
								}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_address2"
										/>
									)
								}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="City"
								showRequiredAsterisk={true}
								name="billing_client_billing_contact_city"
								placeholder="e.g., Irvine"
								value={
									formValues[
										'billing_client_billing_contact_city'
									]
								}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_city"
										/>
									)
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<SelectField
								label="State"
								showRequiredAsterisk={true}
								name="billing_client_billing_contact_state"
								value={
									dropdownSelections.billing_client_billing_contact_state
								}
								options={FORMS.states}
								handleSelect={handleDropdownSelectionChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_state"
										/>
									)
								}
							/>
						</div>
						{dropdownSelections.billing_client_billing_contact_state ===
						'other' ? (
							<div className="sm:col-span-2">
								<GenericField
									required
									type="text"
									label="If other, please specify"
									name="billing_client_billing_contact_state"
									placeholder="e.g., New York"
									value={
										formValues.billing_client_billing_contact_state
									}
									handleInputChange={handleChange}
								/>
							</div>
						) : (
							<div className="sm:col-span-2"></div>
						)}
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="ZIP Code"
								showRequiredAsterisk={true}
								name="billing_client_billing_contact_zip_code"
								placeholder="e.g., 92780"
								value={
									formValues[
										'billing_client_billing_contact_zip_code'
									]
								}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_zip_code"
										/>
									)
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<SelectField
								label="Country"
								options={FORMS.countries}
								handleSelect={handleChange}
								showRequiredAsterisk={true}
								name="billing_client_billing_contact_country"
								value={
									formValues.billing_client_billing_contact_country
								}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_country"
										/>
									)
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Primary Phone Number"
								showRequiredAsterisk={true}
								name="billing_client_billing_contact_phone_number"
								placeholder="e.g., 2137893645"
								value={
									formValues[
										'billing_client_billing_contact_phone_number'
									]
								}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_phone_number"
										/>
									)
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Fax Number"
								name="billing_client_billing_contact_fax_number"
								placeholder="e.g., 2137893645"
								value={
									formValues[
										'billing_client_billing_contact_fax_number'
									] || ''
								}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_fax_number"
										/>
									)
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Email Address"
								name="billing_client_billing_contact_email_address"
								placeholder="e.g., cityofirvine@gmail.com"
								value={
									formValues[
										'billing_client_billing_contact_email_address'
									] || ''
								}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_email_address"
										/>
									)
								}
							/>
						</div>
					</FormGroup>
				</Fragment>
			)}
			{formValues.billing_method === 'patient_self_pay' && (
				<FormGroup heading="Payment Verification">
					<div className="sm:col-span-2">
						<SelectField
							required
							showRequiredAsterisk={true}
							name="billing_patient_payment_received"
							label="Patient Payment Received?"
							handleSelect={handleChange}
							value={
								formValues['billing_patient_payment_received']
							}
							options={FORMS.yes_no_options}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_patient_payment_received"
									/>
								)
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							required
							disabled={
								formValues[
									'billing_patient_payment_received'
								] !== 'true'
							}
							showRequiredAsterisk={true}
							name="billing_patient_payment_payee"
							label="Payment payee"
							handleSelect={handleChange}
							value={formValues['billing_patient_payment_payee']}
							options={
								REQUISITION_FORM['shared']
									.patient_payment_payee_options
							}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_patient_payment_payee"
									/>
								)
							}
						/>
					</div>
				</FormGroup>
			)}
			{formValues['billing_method'] === 'insurance' && (
				<FormGroup heading="Primary insurance company information">
					<div className="sm:col-span-2">
						<SelectField
							name="billing_primary_insurance_insurance_type"
							label="Insurance Type"
							value={
								formValues.billing_primary_insurance_insurance_type
							}
							handleSelect={handleDropdownSelectionChange}
							options={
								REQUISITION_FORM['shared']
									.billing_insurance_types
							}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_insurance_type"
									/>
								)
							}
						/>
					</div>
					<div className="sm:col-span-4"></div>
					<div className="sm:col-span-2">
						<SelectField
							showRequiredAsterisk={true}
							name="billing_primary_insurance_company_name"
							label="Name"
							value={
								dropdownSelections.billing_primary_insurance_company_name
							}
							handleSelect={handleDropdownSelectionChange}
							options={
								REQUISITION_FORM['shared']
									.billing_common_insurance_companies
							}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_company_name"
									/>
								)
							}
						/>
					</div>
					{dropdownSelections.billing_primary_insurance_company_name ===
					'other' ? (
						<Fragment>
							<div className="sm:col-span-2">
								<GenericField
									required
									type="text"
									label="If other, please specify"
									name="billing_primary_insurance_company_name"
									placeholder="e.g., Wellthy Insurance"
									value={
										formValues[
											'billing_primary_insurance_company_name'
										]
									}
									handleInputChange={handleChange}
								/>
							</div>
							<div className="sm:col-span-2"></div>
						</Fragment>
					) : (
						<div className="sm:col-span-4"></div>
					)}
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Address"
							name="billing_primary_insurance_company_address"
							placeholder="e.g., 123 Main St."
							value={
								formValues[
									'billing_primary_insurance_company_address'
								]
							}
							handleInputChange={handleChange}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_company_address"
									/>
								)
							}
						/>
					</div>
					<div className="sm:col-span-4"></div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="City"
							name="billing_primary_insurance_company_city"
							placeholder="e.g., Albany"
							value={
								formValues[
									'billing_primary_insurance_company_city'
								]
							}
							handleInputChange={handleChange}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_company_city"
									/>
								)
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							label="State"
							name="billing_primary_insurance_company_state"
							value={
								dropdownSelections.billing_primary_insurance_company_state
							}
							options={FORMS.states}
							handleSelect={handleDropdownSelectionChange}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_company_state"
									/>
								)
							}
						/>
					</div>
					{dropdownSelections.billing_primary_insurance_company_state ===
					'other' ? (
						<div className="sm:col-span-2">
							<GenericField
								required
								type="text"
								label="If other, please specify"
								name="billing_primary_insurance_company_state"
								placeholder="e.g., New York"
								value={
									formValues.billing_primary_insurance_company_state
								}
								handleInputChange={handleChange}
							/>
						</div>
					) : (
						<div className="sm:col-span-2"></div>
					)}
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="ZIP Code"
							name="billing_primary_insurance_company_zip_code"
							placeholder="e.g., 18279"
							value={
								formValues[
									'billing_primary_insurance_company_zip_code'
								]
							}
							handleInputChange={handleChange}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_company_zip_code"
									/>
								)
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							label="Country"
							options={FORMS.countries}
							handleSelect={handleChange}
							name="billing_primary_insurance_company_country"
							value={
								formValues.billing_primary_insurance_company_country
							}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_company_country"
									/>
								)
							}
						/>
					</div>
				</FormGroup>
			)}
			{['insurance', 'medicare', 'medi-cal'].includes(
				formValues['billing_method']
			) && (
				<FormGroup heading="Primary insurance subscriber information">
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							name="billing_primary_insurance_subscriber_first_name"
							label="First Name"
							placeholder="e.g., Jacob"
							value={
								formValues[
									'billing_primary_insurance_subscriber_first_name'
								]
							}
							handleInputChange={handleChange}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_subscriber_first_name"
									/>
								)
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							name="billing_primary_insurance_subscriber_last_name"
							label="Last Name"
							placeholder="e.g., Torres"
							value={
								formValues[
									'billing_primary_insurance_subscriber_last_name'
								]
							}
							handleInputChange={handleChange}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_subscriber_last_name"
									/>
								)
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<DateField
							label="Date of Birth"
							name="billing_primary_insurance_subscriber_date_of_birth"
							handleInputChange={handleChange}
							value={
								formValues[
									'billing_primary_insurance_subscriber_date_of_birth'
								]
							}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_subscriber_date_of_birth"
									/>
								)
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Phone Number"
							placeholder="e.g., +1 (234) 567-8901"
							name="billing_primary_insurance_subscriber_phone_number"
							value={
								formValues[
									'billing_primary_insurance_subscriber_phone_number'
								]
							}
							handleInputChange={handleChange}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_subscriber_phone_number"
									/>
								)
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							name="billing_primary_insurance_patient_relationship_to_subscriber"
							label="Patient Relationship to Subscriber"
							value={
								formValues[
									'billing_primary_insurance_patient_relationship_to_subscriber'
								]
							}
							handleSelect={handleChange}
							options={
								REQUISITION_FORM[data.sample.assay][
									data.requisition_form.metadata.version
								].patient_relationship_to_subscriber_options
							}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_patient_relationship_to_subscriber"
									/>
								)
							}
						/>
					</div>
					{formValues[
						'billing_primary_insurance_patient_relationship_to_subscriber'
					] === 'other' ? (
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								showRequiredAsterisk={true}
								name="billing_primary_insurance_patient_relationship_to_subscriber_other"
								label="Patient Relationship to Subscriber (if other)"
								placeholder="e.g., Parent or guardian"
								value={
									formValues[
										'billing_primary_insurance_patient_relationship_to_subscriber_other'
									]
								}
								handleInputChange={handleChange}
								fieldAction={
									!hideFlagButton && (
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_primary_insurance_patient_relationship_to_subscriber_other"
										/>
									)
								}
							/>
						</div>
					) : (
						<div className="sm:col-span-2"></div>
					)}
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							showRequiredAsterisk={true}
							name="billing_primary_insurance_subscriber_policy_id_number"
							label="Policy ID #"
							placeholder="e.g., ABC1234567"
							value={
								formValues[
									'billing_primary_insurance_subscriber_policy_id_number'
								]
							}
							handleInputChange={handleChange}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_subscriber_policy_id_number"
									/>
								)
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							name="billing_primary_insurance_subscriber_group_number"
							label="Group #"
							placeholder="e.g., ABC1234567"
							value={
								formValues[
									'billing_primary_insurance_subscriber_group_number'
								]
							}
							handleInputChange={handleChange}
							fieldAction={
								!hideFlagButton && (
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_primary_insurance_subscriber_group_number"
									/>
								)
							}
						/>
					</div>
				</FormGroup>
			)}
			{formValues['billing_method'] === 'insurance' && (
				<Fragment>
					<FormGroup heading="Additional insurance">
						<div className="sm:col-span-2">
							<SelectField
								required
								handleSelect={handleChange}
								name="billing_has_secondary_insurance"
								label="Is there secondary insurance?"
								options={FORMS.yes_no_options}
								value={
									formValues.billing_has_secondary_insurance
								}
							/>
						</div>
					</FormGroup>
					{formValues.billing_has_secondary_insurance === 'true' && (
						<Fragment>
							<FormGroup heading="Secondary insurance company information">
								<div className="sm:col-span-2">
									<SelectField
										name="billing_secondary_insurance_insurance_type"
										label="Insurance Type"
										value={
											formValues.billing_secondary_insurance_insurance_type
										}
										handleSelect={
											handleDropdownSelectionChange
										}
										options={
											REQUISITION_FORM['shared']
												.billing_insurance_types
										}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_insurance_type"
												/>
											)
										}
									/>
								</div>
								<div className="sm:col-span-4"></div>
								<div className="sm:col-span-2">
									<SelectField
										name="billing_secondary_insurance_company_name"
										label="Name"
										showRequiredAsterisk={true}
										value={
											dropdownSelections.billing_secondary_insurance_company_name
										}
										handleSelect={
											handleDropdownSelectionChange
										}
										options={
											REQUISITION_FORM['shared']
												.billing_common_insurance_companies
										}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_company_name"
												/>
											)
										}
									/>
								</div>
								{dropdownSelections.billing_secondary_insurance_company_name ===
								'other' ? (
									<Fragment>
										<div className="sm:col-span-2">
											<GenericField
												required
												type="text"
												label="If other, please specify"
												name="billing_secondary_insurance_company_name"
												placeholder="e.g., Wellthy Insurance"
												value={
													formValues[
														'billing_secondary_insurance_company_name'
													]
												}
												handleInputChange={handleChange}
											/>
										</div>
										<div className="sm:col-span-2"></div>
									</Fragment>
								) : (
									<div className="sm:col-span-4"></div>
								)}
								<div className="sm:col-span-2">
									<GenericField
										type="text"
										label="Address"
										name="billing_secondary_insurance_company_address"
										placeholder="e.g., 123 Main St."
										value={
											formValues[
												'billing_secondary_insurance_company_address'
											]
										}
										handleInputChange={handleChange}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_company_address"
												/>
											)
										}
									/>
								</div>
								<div className="sm:col-span-4"></div>
								<div className="sm:col-span-2">
									<GenericField
										type="text"
										label="City"
										name="billing_secondary_insurance_company_city"
										placeholder="e.g., Albany"
										value={
											formValues[
												'billing_secondary_insurance_company_city'
											]
										}
										handleInputChange={handleChange}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_company_city"
												/>
											)
										}
									/>
								</div>
								<div className="sm:col-span-2">
									<SelectField
										label="State"
										name="billing_secondary_insurance_company_state"
										value={
											dropdownSelections.billing_secondary_insurance_company_state
										}
										options={FORMS.states}
										handleSelect={
											handleDropdownSelectionChange
										}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_company_state"
												/>
											)
										}
									/>
								</div>
								{dropdownSelections.billing_secondary_insurance_company_state ===
								'other' ? (
									<div className="sm:col-span-2">
										<GenericField
											required
											type="text"
											label="If other, please specify"
											name="billing_secondary_insurance_company_state"
											placeholder="e.g., New York"
											value={
												formValues.billing_secondary_insurance_company_state
											}
											handleInputChange={handleChange}
										/>
									</div>
								) : (
									<div className="sm:col-span-2"></div>
								)}
								<div className="sm:col-span-2">
									<GenericField
										type="text"
										label="ZIP Code"
										name="billing_secondary_insurance_company_zip_code"
										placeholder="e.g., 18279"
										value={
											formValues[
												'billing_secondary_insurance_company_zip_code'
											]
										}
										handleInputChange={handleChange}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_company_zip_code"
												/>
											)
										}
									/>
								</div>
								<div className="sm:col-span-2">
									<SelectField
										label="Country"
										options={FORMS.countries}
										handleSelect={handleChange}
										name="billing_secondary_insurance_company_country"
										value={
											formValues.billing_secondary_insurance_company_country
										}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_company_country"
												/>
											)
										}
									/>
								</div>
							</FormGroup>

							<FormGroup heading="Secondary insurance subscriber Information">
								<div className="sm:col-span-2">
									<GenericField
										type="text"
										name="billing_secondary_insurance_subscriber_first_name"
										label="First Name"
										placeholder="e.g., Jacob"
										value={
											formValues[
												'billing_secondary_insurance_subscriber_first_name'
											]
										}
										handleInputChange={handleChange}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_subscriber_first_name"
												/>
											)
										}
									/>
								</div>
								<div className="sm:col-span-2">
									<GenericField
										type="text"
										name="billing_secondary_insurance_subscriber_last_name"
										label="Last Name"
										placeholder="e.g., Torres"
										value={
											formValues[
												'billing_secondary_insurance_subscriber_last_name'
											]
										}
										handleInputChange={handleChange}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_subscriber_last_name"
												/>
											)
										}
									/>
								</div>
								<div className="sm:col-span-2">
									<DateField
										label="Date of Birth"
										name="billing_secondary_insurance_subscriber_date_of_birth"
										handleInputChange={handleChange}
										value={
											formValues[
												'billing_secondary_insurance_subscriber_date_of_birth'
											]
										}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_subscriber_date_of_birth"
												/>
											)
										}
									/>
								</div>
								<div className="sm:col-span-2">
									<GenericField
										type="text"
										label="Phone Number"
										placeholder="e.g., +1 (234) 567-8901"
										name="billing_secondary_insurance_subscriber_phone_number"
										value={
											formValues[
												'billing_secondary_insurance_subscriber_phone_number'
											]
										}
										handleInputChange={handleChange}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_subscriber_phone_number"
												/>
											)
										}
									/>
								</div>
								<div className="sm:col-span-2">
									<SelectField
										name="billing_secondary_insurance_patient_relationship_to_subscriber"
										label="Patient Relationship to Subscriber"
										value={
											formValues[
												'billing_secondary_insurance_patient_relationship_to_subscriber'
											]
										}
										handleSelect={handleChange}
										options={
											REQUISITION_FORM[data.sample.assay][
												data.requisition_form.metadata
													.version
											]
												.patient_relationship_to_subscriber_options
										}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_patient_relationship_to_subscriber"
												/>
											)
										}
									/>
								</div>
								{formValues[
									'billing_secondary_insurance_patient_relationship_to_subscriber'
								] === 'other' ? (
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											showRequiredAsterisk={true}
											name="billing_secondary_insurance_patient_relationship_to_subscriber_other"
											label="Patient Relationship to Subscriber (if other)"
											placeholder="e.g., Parent or guardian"
											value={
												formValues[
													'billing_secondary_insurance_patient_relationship_to_subscriber_other'
												]
											}
											handleInputChange={handleChange}
											fieldAction={
												!hideFlagButton && (
													<FlagButton
														formValues={formValues}
														setFormValues={
															setFormValues
														}
														property="billing_secondary_insurance_patient_relationship_to_subscriber_other"
													/>
												)
											}
										/>
									</div>
								) : (
									<div className="sm:col-span-2"></div>
								)}
								<div className="sm:col-span-2">
									<GenericField
										type="text"
										showRequiredAsterisk={true}
										name="billing_secondary_insurance_subscriber_policy_id_number"
										label="Policy ID #"
										placeholder="e.g., ABC1234567"
										value={
											formValues[
												'billing_secondary_insurance_subscriber_policy_id_number'
											]
										}
										handleInputChange={handleChange}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_subscriber_policy_id_number"
												/>
											)
										}
									/>
								</div>
								<div className="sm:col-span-2">
									<GenericField
										type="text"
										name="billing_secondary_insurance_subscriber_group_number"
										label="Group #"
										placeholder="e.g., ABC1234567"
										value={
											formValues[
												'billing_secondary_insurance_subscriber_group_number'
											]
										}
										handleInputChange={handleChange}
										fieldAction={
											!hideFlagButton && (
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="billing_secondary_insurance_subscriber_group_number"
												/>
											)
										}
									/>
								</div>
							</FormGroup>
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
}
