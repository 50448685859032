/* eslint-disable @typescript-eslint/no-empty-function */
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	Dispatch,
	Fragment,
	useState,
	FormEvent,
	useEffect,
	useContext,
	ChangeEvent,
	createContext,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	FORMS,
	Note,
	Option,
	formatDate,
	getRoleFromPathname,
} from '@pangea-lis-apps/utils';
import {
	Form,
	Modal,
	Button,
	useAxios,
	FormGroup,
	FormFooter,
	LoadingBox,
	TextButton,
	NumberField,
	SelectField,
	GenericField,
	TextareaField,
	DescriptionList,
	DescriptionItem,
	MultipleCombobox,
} from '@pangea-lis-apps/ui';

const initialModalState = {
	add: false,
	edit: false,
	delete: false,
};

interface ModalState {
	add: boolean;
	edit: boolean;
	delete: boolean;
}

const DataContext = createContext<{
	id: string | undefined;
	collection: 'data' | 'orders';
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: ModalState;
		setVisible: Dispatch<SetStateAction<ModalState>>;
	};
}>({
	id: undefined,
	collection: 'data',
	setRefresh: () => {},
	visible: { visible: initialModalState, setVisible: () => {} },
});

interface NotesProps {
	data: Note[];
	id: string | undefined;
	receiveDepartment?: string;
	collection: 'data' | 'orders';
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function notesReceiveDepartmentFilter(
	notes: Note[],
	receiveDepartment: string
): Array<Note> {
	const results = [];

	for (let i = 0; i < notes.length; i++) {
		if (
			notes[i].receiving_departments?.includes('all') ||
			notes[i].receiving_departments?.includes(receiveDepartment)
		) {
			results.push(notes[i]);
		}
	}

	return results;
}

function noteReceiveDepartmentCheck(
	note: Note,
	receiveDepartment: string | undefined
): boolean {
	if (!receiveDepartment) return true;

	if (note.sending_department === receiveDepartment) return true;

	if (note.receiving_departments?.includes('all')) return true;

	if (note.receiving_departments?.includes(receiveDepartment)) return true;

	return false;
}

/**
 * Notes
 *
 * This function works for adding notes for both order documents and data documents.
 *
 * @param id: The ObjectId of the document in question.
 * @param data: An array of note objects.
 * @param collection: The name of the collection where the document resides
 * @returns ReactNode
 */
export default function Notes(props: NotesProps) {
	const { user } = useAuth0();

	const [modalVisible, setModalVisible] =
		useState<ModalState>(initialModalState);
	const [selectedNote, setSelectedNote] = useState<Note | undefined>(
		undefined
	);

	return (
		<DataContext.Provider
			value={{
				id: props.id,
				collection: props.collection,
				setRefresh: props.setRefresh,
				visible: {
					visible: modalVisible,
					setVisible: setModalVisible,
				},
			}}
		>
			<AddNoteModal />
			{props.data.length && user ? (
				<Fragment>
					<EditNoteModal selectedNote={selectedNote} />
					<DeleteNoteModal selectedNote={selectedNote} />
					<ul className="divide-y divide-gray-200 first:pt-0">
						{props.data.map((note) =>
							noteReceiveDepartmentCheck(
								note,
								props.receiveDepartment
							) ? (
								<li
									key={note.id}
									className="py-4 first:pt-0 last:pb-0"
								>
									<div className="mb-2 flex items-start justify-between">
										<div className="space-x-2">
											<span className="font-medium text-sm text-gray-600">
												{note.metadata.created_by.first_name.concat(
													' ',
													note.metadata.created_by
														.last_name
												)}
											</span>
											<span className="text-sm text-gray-500">
												{formatDate(
													note.metadata.date_modified
														.$date,
													true
												)}
											</span>
											{note.edited && (
												<span className="italic text-sm text-gray-400">
													Edited
												</span>
											)}
										</div>
										{note.metadata.created_by.id ===
											user.sub && (
											<div className="flex items-center justify-end space-x-2">
												<TextButton
													text="Edit"
													color="gray"
													type="button"
													onClick={() => {
														setSelectedNote(note);
														setModalVisible(
															(prevValue) => ({
																...prevValue,
																edit: true,
															})
														);
													}}
												/>
												<TextButton
													color="gray"
													type="button"
													text="Delete"
													onClick={() => {
														setSelectedNote(note);
														setModalVisible(
															(prevValue) => ({
																...prevValue,
																delete: true,
															})
														);
													}}
												/>
											</div>
										)}
									</div>
									<p className="inline text-sm">
										{note.content}
									</p>
								</li>
							) : undefined
						)}
					</ul>
				</Fragment>
			) : (
				<p className="text-sm text-gray-400">None</p>
			)}

			<div className="flex items-center justify-end">
				<TextButton
					color="blue"
					type="button"
					text="Add note"
					className="mt-2"
					onClick={() =>
						setModalVisible((prevValue) => ({
							...prevValue,
							add: true,
						}))
					}
				/>
			</div>
		</DataContext.Provider>
	);
}

interface ModalProps {
	selectedNote?: Note | undefined;
}

const EditNoteModal = (props: ModalProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const role = getRoleFromPathname();

	const {
		id,
		collection,
		setRefresh,
		visible: { visible, setVisible },
	} = useContext(DataContext);
	const [note, setNote] = useState('');
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (visible.edit && props.selectedNote)
			setNote(props.selectedNote.content);
	}, [props.selectedNote, visible]);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (
			disabled ||
			!axios ||
			!props.selectedNote ||
			!user ||
			!id ||
			!collection
		)
			return;
		else if (note === '') {
			toast.error('To delete the note, use the delete button.');
		} else if (note === props.selectedNote.content) {
			toast.error('No updates were made to the notes.');
			return;
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).post(
				`/api/${role}/${collection}/${id}/update-note/${props.selectedNote.id}/?auth0_user_id=${user.sub}`,
				{
					user,
					new_note: note,
				}
			);

			toast.dismiss();

			setRefresh((value) => !value);
			handleClose();
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLTextAreaElement;

		if (target && target.name) setNote(target.value);
	};

	const handleClose = () => {
		setDisabled(false);
		setVisible((prevValue) => ({
			...prevValue,
			edit: false,
		}));
	};

	return (
		<Modal
			title="Edit note"
			onClose={handleClose}
			visible={visible.edit}
			customWidth="max-w-sm"
			description="Make changes to an existing note."
		>
			{!props.selectedNote ? (
				<LoadingBox />
			) : (
				<Form handleSubmit={handleSubmit}>
					<FormGroup>
						<div className="sm:col-span-6">
							<TextareaField
								required
								name="note"
								label="Note"
								value={note}
								handleInputChange={handleChange}
								placeholder="e.g., Cellular control failed but sample is positive."
							/>
						</div>
					</FormGroup>
					<FormFooter>
						<Button
							text="Update"
							type="submit"
							tier="tertiary"
							Icon="CheckIcon"
						/>
					</FormFooter>
				</Form>
			)}
		</Modal>
	);
};

const DeleteNoteModal = (props: ModalProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const role = getRoleFromPathname();

	const {
		id,
		collection,
		setRefresh,
		visible: { visible, setVisible },
	} = useContext(DataContext);
	const [disabled, setDisabled] = useState(false);
	const [confirmation, setConfirmation] = useState('');

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (
			disabled ||
			!axios ||
			!props.selectedNote ||
			!user ||
			!id ||
			!collection
		)
			return;
		else if (!confirmation) {
			toast.error('Enter delete to confirm!');
			return;
		}

		setDisabled(true);

		toast.loading('Deleting...', toastOptions);

		try {
			await (
				await axios
			).post(
				`/api/${role}/${collection}/${id}/delete-note/${props.selectedNote.id}/?auth0_user_id=${user.sub}`,
				{ user }
			);

			toast.dismiss();

			setRefresh((value) => !value);
			handleClose();
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<HTMLInputElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement;

		if (target && target.name) setConfirmation(target.value);
	};

	const handleClose = () => {
		setDisabled(false);
		setConfirmation('');
		setVisible((prevValue) => ({
			...prevValue,
			delete: false,
		}));
	};

	return (
		<Modal
			title="Delete note"
			onClose={handleClose}
			customWidth="max-w-sm"
			visible={visible.delete}
			description="This action is irreversible. Please confirm deletion."
		>
			{!props.selectedNote ? (
				<LoadingBox />
			) : (
				<div className="space-y-4">
					<DescriptionList>
						<DescriptionItem
							term="Note"
							customColSpan="sm:col-span-3"
							details={props.selectedNote.content}
						/>
					</DescriptionList>

					<Form handleSubmit={handleSubmit}>
						<FormGroup>
							<div className="sm:col-span-6">
								<GenericField
									required
									type="text"
									name="confirmation"
									value={confirmation}
									label={
										<span>
											Enter{' '}
											<span className="italic">
												delete
											</span>{' '}
											to confirm
										</span>
									}
									handleInputChange={handleChange}
									placeholder="e.g., delete"
								/>
							</div>
						</FormGroup>
						<FormFooter>
							<Button
								text="Delete"
								type="submit"
								tier="tertiary"
								Icon="TrashIcon"
							/>
						</FormFooter>
					</Form>
				</div>
			)}
		</Modal>
	);
};

interface FormValuesState {
	note: string;
	departments: Option[];
	follow_up_with: string;
	follow_up_in_days: number;
	follow_up_required: string;
	follow_up_with_other: string;
}

const initialFormValues = {
	note: '',
	departments: [],
	follow_up_with: '',
	follow_up_in_days: 0,
	follow_up_required: '',
	follow_up_with_other: '',
};

const AddNoteModal = (props: ModalProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const role = getRoleFromPathname();

	const {
		id,
		collection,
		setRefresh,
		visible: { visible, setVisible },
	} = useContext(DataContext);
	const [formValues, setFormValues] =
		useState<FormValuesState>(initialFormValues);
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !id || !collection) return;
		else if (!formValues.note) {
			toast.error('Enter a note!');
			return;
		}

		setDisabled(true);

		toast.loading('Adding note...', toastOptions);

		try {
			await (
				await axios
			).post(`/api/${role}/${collection}/${id}/note`, {
				form_data: formValues,
			});

			toast.dismiss();

			handleClose();
			setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<
		HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement
	> = (event: ChangeEvent) => {
		const target = event.target as
			| HTMLTextAreaElement
			| HTMLSelectElement
			| HTMLInputElement;

		if (target && target.name)
			setFormValues((prevValue) => {
				if (target.name === 'follow_up_required')
					return {
						...prevValue,
						follow_up_with: '',
						follow_up_in_days: 0,
						follow_up_with_other: '',
						follow_up_required: target.value,
					};

				return {
					...prevValue,
					[target.name]: target.value,
				};
			});
	};

	const handleClose = () => {
		setFormValues(initialFormValues);
		setDisabled(false);
		setVisible((prevValue) => ({
			...prevValue,
			add: false,
		}));
	};

	const [departmentOptions, setDepartmentOptions] = useState([
		{
			value: '',
			disabled: true,
			label: 'Select option(s)',
		},
		{
			value: 'all',
			label: 'All',
			disabled: false,
			disables_all: true,
		},
		{
			value: 'accessioning',
			label: 'Accessioning team',
			disabled: false,
		},
		{
			value: 'client_services',
			label: 'Client services team',
			disabled: false,
		},
		{
			value: 'shipping',
			label: 'Shipping team',
			disabled: false,
		},
		{
			label: 'Lab tech team',
			value: 'lab_techs',
			disabled: false,
		},
		{
			label: 'CLS team',
			value: 'cls',
			disabled: false,
		},
		{
			value: 'medical_billing',
			label: 'Medical billing team',
			disabled: false,
		},
	]);

	const handleMultipleComboboxSelect = (selectedOptions: Option[]) => {
		setFormValues((prevValues) => {
			if (prevValues) {
				// Get the new element
				const newElement = selectedOptions.slice(-1)[0];

				// Check if the element exists in the running list
				const exists =
					newElement &&
					prevValues.departments.find(
						(element) => element.value === newElement.value
					);

				// If element exists, then remove, otherwise, add
				let newArray = exists
					? prevValues.departments.filter(
							(element) => element.value !== newElement.value
					  )
					: selectedOptions.map((option) => option);

				// If new element is an all-disabling element
				if (newElement && newElement.disables_all) {
					setDepartmentOptions((prevValue) =>
						prevValue.map((val) => {
							if (val.value === '') return val;

							return {
								...val,
								disabled: false,
							};
						})
					);

					// If the element is being added, then disable and remove all the other selected options all the other ones
					if (!exists) {
						newArray = [newElement];

						setDepartmentOptions((prevValue) =>
							prevValue.map((val) => {
								if (val.value === newElement.value) return val;

								return {
									...val,
									disabled: true,
								};
							})
						);
					}
				}

				return {
					...prevValues,
					departments: newArray,
				};
			}

			return prevValues;
		});
	};

	return (
		<Modal
			title="Add note"
			visible={visible.add}
			onClose={handleClose}
			description="Notes are for internal use only."
			customWidth={role === 'medical-biller' ? 'max-w-lg' : 'max-w-md'}
		>
			<Form>
				<FormGroup
					heading="Details"
					description="Notes you make will be viewable by everyone in your team."
				>
					<div className="sm:col-span-6">
						<MultipleCombobox
							required
							label="Viewable to"
							name="departments"
							options={departmentOptions}
							value={formValues.departments}
							handleSelect={handleMultipleComboboxSelect}
						/>
					</div>
					<div className="sm:col-span-6">
						<TextareaField
							required
							name="note"
							label="Note"
							value={formValues.note}
							handleInputChange={handleChange}
							placeholder="Enter your note here"
						/>
					</div>
				</FormGroup>
				{role === 'medical-biller' && (
					<FormGroup heading="Follow up information">
						<div className="sm:col-span-3">
							<SelectField
								required
								name="follow_up_required"
								label="Follow Up Required?"
								value={formValues.follow_up_required}
								handleSelect={handleChange}
								options={FORMS.yes_no_options}
							/>
						</div>
						<div className="sm:col-span-3"></div>
						{formValues.follow_up_required === 'true' && (
							<Fragment>
								<div className="sm:col-span-3">
									<SelectField
										required
										name="follow_up_with"
										label="Follow up with?"
										value={formValues.follow_up_with}
										handleSelect={handleChange}
										options={[
											{
												value: '',
												disabled: true,
												label: 'Select an Option',
											},
											{
												label: 'Patient',
												value: 'patient',
											},
											{
												label: 'Clinic',
												value: 'clinic',
											},
											{
												label: 'Insurance',
												value: 'insurance',
											},
											{
												label: 'Other',
												value: 'other',
											},
										]}
									/>
								</div>
								{formValues.follow_up_with === 'other' ? (
									<div className="sm:col-span-3">
										<GenericField
											required
											type="text"
											name="follow_up_with_other"
											label="If other, enter details"
											value={
												formValues.follow_up_with_other
											}
											handleInputChange={handleChange}
											placeholder="e.g., Client"
										/>
									</div>
								) : (
									<div className="sm:col-span-3"></div>
								)}
								<div className="sm:col-span-3">
									<NumberField
										min={1}
										required
										max={30}
										name="follow_up_in_days"
										label="In how many days?"
										handleInputChange={handleChange}
										value={formValues.follow_up_in_days}
									/>
								</div>
							</Fragment>
						)}
					</FormGroup>
				)}
				<FormFooter>
					<Button
						text="Add"
						type="button"
						tier="tertiary"
						Icon="PlusIcon"
						disabled={disabled}
						onClick={handleSubmit}
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
};
