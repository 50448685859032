/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useParams } from 'react-router-dom';
import { useId, useState, useEffect, Fragment } from 'react';

import {
	useAxios,
	Container,
	LoadingBox,
	TRFComponents,
	ContentSection,
	ContentWrapper,
	DescriptionList,
	DescriptionItem,
	AccessioningStatus,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	Option,
	ASSAYS,
	ActionLog,
	ClinicData,
	Pagination,
	requiredStyles,
	getSpecimenTypes,
	getLabelFromValue,
	initialPaginationValues,
	generateLabelForOrderTests,
} from '@pangea-lis-apps/utils';

import Notes from '../../../components/notes';
import ActionLogTable from '../../../components/tables/action-logs-table';

/* eslint-disable-next-line */
export interface ViewTestRequisitionFormProps {
	function?: string;
}

export default function ViewTestRequisitionForm(
	props: ViewTestRequisitionFormProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { dataId } = useParams();

	const [refresh, setRefresh] = useState(false);
	const [data, setData] = useState<ClinicData | undefined>(undefined);

	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);
	const [requisitionFormHistory, setRequisitionFormHistory] = useState<
		ActionLog[] | undefined
	>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: { data: data1 },
				} = await (
					await axios
				).get(`/api/client-services/data/${dataId}`);

				if (!unmounted) {
					// Requisition form data
					const parsedData = JSON.parse(data1);
					console.log(parsedData);

					setData(parsedData);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		axios,
		dataId,
		refresh,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			setRequisitionFormHistory(undefined);

			try {
				const {
					data: {
						data: { data: data2, total_entries },
					},
				} = await (
					await axios
				).get(
					`/api/client-services/data/${dataId}/action-history?type=requisition_form&page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`
				);

				if (!unmounted) {
					const parsedData2 = JSON.parse(data2);
					console.log(parsedData2);

					setRequisitionFormHistory(parsedData2);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		axios,
		dataId,
		refresh,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	const clinicSample = data?.type === 'clinic';

	return (
		<Container>
			<ContentWrapper
				Icon="DocumentTextIcon"
				heading="Requisition form details"
			>
				{!data ? (
					<LoadingBox />
				) : (
					<Fragment>
						<AccessioningStatus data={data} />

						<ContentSectionDivider />

						<TRFComponents.ProviderInformation data={data} />

						<ContentSectionDivider />

						<TRFComponents.OrganizationInformation data={data} />

						<ContentSectionDivider />

						{clinicSample ? (
							<ClinicSampleInformation data={data} />
						) : (
							<NonClinicSampleInformation data={data} />
						)}

						<ContentSectionDivider />

						<ContentSection heading="Miscellaneous">
							<DescriptionList>
								<DescriptionItem
									term="Notes"
									customColSpan="sm:col-span-3"
								>
									<Notes
										id={dataId}
										collection="data"
										setRefresh={setRefresh}
										data={data.notes}
										receiveDepartment="client_services"
									/>
								</DescriptionItem>
							</DescriptionList>
						</ContentSection>

						{requisitionFormHistory && (
							<Fragment>
								<ContentSection
									heading="Requisition form history"
									description="Log of actions taken on the requisition sections of the portal."
								>
									<ActionLogTable
										type="requisition_form"
										pagination={{
											pagination,
											setPagination,
										}}
										data={requisitionFormHistory}
									/>
								</ContentSection>

								<ContentSectionDivider />
							</Fragment>
						)}
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}

interface SampleInformationProps {
	data: ClinicData;
}

const NonClinicSampleInformation = ({ data }: SampleInformationProps) => {
	return (
		<ContentSection heading="Order and specimen information">
			<DescriptionList>
				<DescriptionItem
					term="Assay"
					termStyles={requiredStyles(
						data.requisition_form.sample_assay
					)}
					details={(() => {
						const assay = ASSAYS['assays'].filter(
							(assay: Option) =>
								assay.value ===
								data.requisition_form.sample_assay
						);

						if (assay.length) return assay[0].label;

						return undefined;
					})()}
				/>
				<DescriptionItem
					term="Specimen Type"
					termStyles={requiredStyles(
						data.requisition_form.sample_specimen_type
					)}
					details={getLabelFromValue(
						data.requisition_form.sample_specimen_type,
						getSpecimenTypes()
					)}
				/>
				<DescriptionItem
					term="Tests"
					termStyles={requiredStyles(
						data.requisition_form.order_tests &&
							data.requisition_form.order_tests.length
							? data.requisition_form.order_tests.join(', ')
							: ''
					)}
					details={generateLabelForOrderTests(
						data.requisition_form.order_tests
					)}
				/>
			</DescriptionList>
		</ContentSection>
	);
};

const ClinicSampleInformation = ({ data }: SampleInformationProps) => {
	return (
		<Fragment>
			<TRFComponents.OrderAndSpecimenInformation data={data} />

			<ContentSectionDivider />

			<TRFComponents.PatientInformation data={data} />

			<ContentSectionDivider />

			<TRFComponents.BillingInformation data={data} />

			<ContentSectionDivider />

			<TRFComponents.MiscAndAttachments
				data={data}
				entity="client-services"
			/>
		</Fragment>
	);
};
