import { capitalize } from '../../../../format';

// import { FITResults } from '../../../types/data';

type Results = Record<string, string | null>;

/**
 * Stringifies results so they can be added to comments or downloadable Excel files.
 *
 * @param {Object} results - The results for a sample.
 * @returns {string} The stringified version of the results.
 */
const stringifyResults = (results: Results): string => {
	const resultsList = [];
	const skip = ['by', 'date'];

	for (const [key, value] of Object.entries(results)) {
		if (skip.includes(key)) {
			continue;
		}

		let formattedKey;
		if (key === 'result') {
			formattedKey = 'Result';
		} else {
			throw new Error('Unknown FIT results property');
		}

		const formattedValue = value
			? capitalize((value as string).replace('_', ' '))
			: 'null';
		resultsList.push(`${formattedKey}: ${formattedValue}`);
	}

	return resultsList.join(', ');
};

export default { stringifyResults };
