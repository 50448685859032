import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useId,
	Fragment,
	useState,
	Dispatch,
	FormEvent,
	useEffect,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Note,
	Batch,
	FORMS,
	Option,
	BATCHES,
	IconName,
	capitalize,
	formatDate,
	Pagination,
	ClinicData,
	NonClinicData,
	ORGANIZATIONS,
	getSampleStatuses,
	getLabelFromValue,
	normalizeFormValues,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';
import {
	Form,
	Alert,
	Modal,
	Table,
	Button,
	DataCell,
	TableRow,
	useAxios,
	DateField,
	Container,
	TableHead,
	TableBody,
	FormGroup,
	LoadingBox,
	EmptyTable,
	HeaderCell,
	FormFooter,
	TextButton,
	SelectField,
	GenericField,
	TextareaField,
	CheckboxField,
	ContentWrapper,
	ContentSection,
	DescriptionList,
	ArrowLinkButton,
	DescriptionItem,
	ButtonWithDropdown,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import NotesTable from '../../../components/tables/notes-table';
import ActionLogTable from '../../../components/tables/action-logs-table';

const ExtractionKitOptions = [
	{
		label: 'Select an option',
		value: '',
	},
	{
		label: 'Urine Care™ DNA Extraction Kit',
		value: 'Urine Care™ DNA Extraction Kit',
	},
	{
		label: 'Other',
		value: 'other',
	},
];

const PCRKitOptions = [
	{
		label: 'Select an option',
		value: '',
	},
	{
		label: 'Bladder CARE™ Kit',
		value: 'Bladder CARE™ Kit',
	},
	{
		label: 'Other',
		value: 'other',
	},
];

const DCCKitOptions = [
	{
		label: 'Select an option',
		value: '',
	},
	{
		label: 'DNA Clean & Concentrator Magbead Kit',
		value: 'DNA Clean & Concentrator Magbead Kit',
	},
	{
		label: 'Other',
		value: 'other',
	},
];

const projectedSampleFields = [
	'_id',
	'type',
	'sample.priority',
	'sample.status',
	'metadata.accessioning.completed.value',
	'sample.pangea_id',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'sample.sample_received_date',
	'sample.sample_collection_date',
	'sample.misc.sample_stability.stable_until',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'rerun_batch_remove',
	'send_rerun_email_notification',
];

type EditBatchAction = 'remove' | 'rerun';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewBatchProps {}

export default function ViewBatch(props: ViewBatchProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const navigate = useNavigate();
	const { batchId } = useParams();

	const [refresh, setRefresh] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const [mainCheckbox, setMainCheckbox] = useState(false);
	const [batch, setBatch] = useState<Batch | undefined>(undefined);

	// Batch notes
	const [batchNotes, setBatchNotes] = useState<Note[]>();
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !batchId) return;

			setBatch(undefined);
			setBatchNotes(undefined);

			try {
				const [
					{
						data: { data: data1 },
					},
					{
						data: {
							data: { data: data2, total_entries },
						},
					},
				] = await Promise.all([
					await (
						await axios
					).post(`/api/cls/batches/${batchId}`, {
						projected_sample_fields: projectedSampleFields,
					}),
					await (
						await axios
					).get(
						`/api/cls/batches/${batchId}/notes?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`
					),
				]);

				if (!unmounted) {
					console.log(JSON.parse(data1));
					const parsedData1 = JSON.parse(data1);

					setBatch(() => {
						return {
							...parsedData1,
							samples: parsedData1.samples.map(
								(option: ClinicData | NonClinicData) => ({
									...option,
									checked: false,
									rerun_batch_remove: false,
									send_rerun_email_notification: false,
								})
							),
						};
					});

					console.log(JSON.parse(data2));
					const parsedData2 = JSON.parse(data2);

					setBatchNotes(parsedData2);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		axios,
		refresh,
		batchId,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	// Checkbox functions
	const handleSelectRow = (sampleId: string) => {
		setBatch((prevVal) => {
			if (prevVal) {
				return {
					...prevVal,
					samples: prevVal.samples.map((x_data) => {
						if (x_data._id.$oid === sampleId)
							return {
								...x_data,
								checked: !x_data.checked,
							};
						else return x_data;
					}),
				};
			}

			return;
		});
	};

	const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
		if (batch) {
			setMainCheckbox(true);

			setBatch((prevVal) => {
				if (prevVal)
					return {
						...prevVal,
						samples: prevVal.samples.map((data) => ({
							...data,
							checked: event.target.checked,
						})),
					};

				return;
			});
		}
	};

	useEffect(() => {
		if (batch) {
			let count = 0;

			for (const sample of batch.samples) {
				if (sample.checked) count += 1;
			}

			setShowButton(Boolean(count));
			setMainCheckbox(
				Boolean(batch.samples.length) && count === batch.samples.length
			);
		}
	}, [batch]);

	// Action buttons
	const [visible, setVisible] = useState(false);
	const [editQCModalVisible, setEditQCModalVisible] = useState(false);
	const [action, setAction] = useState<EditBatchAction>('remove');

	// Download results as Excel file
	const [downloadDisabled, setDownloadDisabled] = useState(false);

	const handleDownload = async () => {
		if (downloadDisabled || !axios || !batch) return;

		setDownloadDisabled(true);

		toast.loading('Downloading...', toastOptions);

		try {
			const { data } = await (
				await axios
			).get(`/api/cls/batch/${batchId}/download`, {
				responseType: 'arraybuffer',
			});

			toast.dismiss(toastId);

			const blob = new Blob([data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`Batch ${batch.name} Samples List ${new Date().toLocaleString(
					'en-US',
					{
						month: '2-digit',
						day: '2-digit',
						year: 'numeric',
						hour: '2-digit',
						minute: '2-digit',
						hour12: true,
					}
				)}.xlsx`
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);

			setDownloadDisabled(false);
		} catch (error) {
			console.log(error);
			setDownloadDisabled(false);
		}
	};

	return (
		<Fragment>
			<ConfirmActionModal
				batch={batch}
				action={action}
				setBatch={setBatch}
				setRefresh={setRefresh}
				visible={{ visible, setVisible }}
			/>
			<EditQCModal
				batch={batch}
				setRefresh={setRefresh}
				visible={{
					visible: editQCModalVisible,
					setVisible: setEditQCModalVisible,
				}}
			/>
			<Container>
				<ContentWrapper
					heading="Batch details"
					Icon="RectangleStackIcon"
				>
					{!(batch && batchNotes) ? (
						<LoadingBox />
					) : (
						<Fragment>
							<ContentSection heading="Details">
								<DescriptionList>
									<DescriptionItem
										term="Name"
										details={batch.name}
									/>
									<DescriptionItem
										term="Date created"
										details={formatDate(
											batch.metadata.date_created.$date
										)}
									/>
									<DescriptionItem
										term="# of samples"
										details={
											batch.samples.length
												? batch.samples.length
												: '0'
										}
									/>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection
								heading="QC"
								headingAction={
									<TextButton
										color="blue"
										text="Update"
										type="button"
										onClick={() =>
											setEditQCModalVisible(true)
										}
									/>
								}
							>
								<DescriptionList>
									<DescriptionItem
										term="Instrument ID"
										details={
											batch.qc.instrument_id || 'N/A'
										}
									/>
									<div className="sm:col-span-2"></div>
									<DescriptionItem
										term="Passed?"
										details={
											batch.qc.passed
												? getLabelFromValue(
														batch.qc.passed,
														BATCHES.qc_status_options
												  )
												: 'N/A'
										}
									/>
									<div className="sm:col-span-2"></div>
									<DescriptionItem
										term="Bladder CARE™ Positive Sample value?"
										details={
											batch.qc.positive_sample.value ||
											'N/A'
										}
									/>
									<DescriptionItem
										term="Bladder CARE™ Positive Sample result?"
										details={
											batch.qc.positive_sample.result
												? getLabelFromValue(
														batch.qc.positive_sample
															.result,
														BATCHES.qc_sample_result_options
												  )
												: 'N/A'
										}
									/>
									<div className="sm:col-span-1"></div>
									<DescriptionItem
										term="Bladder CARE™ Negative Sample value?"
										details={
											batch.qc.negative_sample.value ||
											'N/A'
										}
									/>
									<DescriptionItem
										term="Bladder CARE™ Negative Sample result?"
										details={
											batch.qc.negative_sample.result
												? getLabelFromValue(
														batch.qc.negative_sample
															.result,
														BATCHES.qc_sample_result_options
												  )
												: 'N/A'
										}
									/>
									<div className="sm:col-span-1"></div>
									<DescriptionItem
										term="Extraction Kit Name"
										details={
											getLabelFromValue(
												batch.qc.extraction_kit
													.kit_name,
												ExtractionKitOptions
											) || 'N/A'
										}
									/>
									<DescriptionItem
										term="Extraction Kit Lot #"
										details={
											batch.qc.extraction_kit
												.lot_number || 'N/A'
										}
									/>
									<DescriptionItem
										term="Extraction Kit Expiration Date"
										details={
											formatDate(
												batch.qc.extraction_kit
													.expiration_date
											) || 'N/A'
										}
									/>
									<DescriptionItem
										term="PCR Kit Name"
										details={
											getLabelFromValue(
												batch.qc.pcr_kit.kit_name,
												PCRKitOptions
											) || 'N/A'
										}
									/>
									<DescriptionItem
										term="PCR Kit Lot #"
										details={
											batch.qc.pcr_kit.lot_number || 'N/A'
										}
									/>
									<DescriptionItem
										term="PCR Kit Expiration Date"
										details={
											formatDate(
												batch.qc.pcr_kit.expiration_date
											) || 'N/A'
										}
									/>
									<DescriptionItem
										term="DCC Applicable?"
										details={
											batch.qc.dcc_kit.applicable
												? 'Yes'
												: 'No'
										}
									/>
									<div className="sm:col-span-2"></div>
									{batch.qc.dcc_kit.applicable && (
										<Fragment>
											<DescriptionItem
												term="DCC Kit Name"
												details={
													getLabelFromValue(
														batch.qc.dcc_kit
															.kit_name,
														DCCKitOptions
													) || 'N/A'
												}
											/>
											<DescriptionItem
												term="DCC Kit Lot #"
												details={
													batch.qc.dcc_kit
														.lot_number || 'N/A'
												}
											/>
											<DescriptionItem
												term="DCC Kit Expiration Date"
												details={
													formatDate(
														batch.qc.dcc_kit
															.expiration_date
													) || 'N/A'
												}
											/>
										</Fragment>
									)}
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection heading="Samples">
								<BatchRecordTable
									batch={batch}
									setRefresh={setRefresh}
									mainCheckbox={mainCheckbox}
									handleSelectRow={handleSelectRow}
									handleSelectAll={handleSelectAll}
								/>

								<FormFooter className="!border-none !mt-4">
									{(() => {
										const items: {
											name: string;
											Icon: IconName;
											disabled?: boolean;
											handleClick: () => void;
										}[] = [
											{
												name: 'Add samples',
												Icon: 'PlusIcon',
												handleClick: () =>
													navigate(
														`/cls/update/batch/${batch._id.$oid}/add-samples`
													),
											},
											{
												name: 'Download',
												Icon: 'ArrowDownTrayIcon',
												handleClick: handleDownload,
											},
											// {
											// 	name: 'Remove from batch',
											// 	Icon: 'XCircleIcon',
											// 	disabled: !showButton,
											// 	handleClick: () => {
											// 		setAction('remove');
											// 		setVisible(true);
											// 	},
											// },
											{
												name: 'Rerun sample(s)',
												Icon: 'ExclamationCircleIcon',
												disabled: !showButton,
												handleClick: () => {
													setAction('rerun');
													setVisible(true);
												},
											},
										];

										return (
											<ButtonWithDropdown
												Icon={items[0].Icon}
												items={items.slice(1)}
												mainButtonText={items[0].name}
												mainClick={items[0].handleClick}
												mainDisabled={items[0].disabled}
											/>
										);
									})()}
								</FormFooter>
							</ContentSection>

							<ContentSectionDivider />

							<NotesTable
								notes={batchNotes}
								dataId={batch._id.$oid}
								setRefresh={setRefresh}
								pagination={{ pagination, setPagination }}
							/>

							<ContentSectionDivider />

							<ContentSection heading="Batch logs">
								<ActionLogTable data={batch.logs} />
							</ContentSection>
						</Fragment>
					)}
				</ContentWrapper>
			</Container>
		</Fragment>
	);
}

interface ConfirmActionModalProps {
	action: EditBatchAction;
	batch: Batch | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	setBatch: Dispatch<SetStateAction<Batch | undefined>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

export function ConfirmActionModal(props: ConfirmActionModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { batchId } = useParams();
	const { visible, setVisible } = props.visible;

	const [message, setMessage] = useState('');
	const [disabled, setDisabled] = useState(false);

	const handleAction = async (action: EditBatchAction) => {
		if (disabled || !axios || !props.batch) return;
		else if (
			props.action === 'remove' &&
			(!message || message !== 'Remove')
		) {
			toast.error('Enter Remove to confirm!', toastOptions);
			return;
		} else if (props.action === 'rerun' && !message) {
			toast.error('Enter a message!', toastOptions);
			return;
		}

		setDisabled(true);

		const selectedSamples = props.batch.samples
			.filter((x_data) => x_data.checked)
			.map((x_data) => ({
				_id: x_data._id.$oid,
				rerun_batch_remove: x_data.rerun_batch_remove,
				send_rerun_email_notification:
					x_data.send_rerun_email_notification,
			}));

		toast.loading(
			action === 'remove' ? 'Removing...' : 'Marking as rerun...',
			toastOptions
		);

		try {
			await (
				await axios
			).patch(`/api/cls/batch/${batchId}/actions`, {
				action,
				message,
				samples: selectedSamples,
			});

			handleClose();
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}

		toast.dismiss(toastId);
	};

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLTextAreaElement;

		if (target && target.name) setMessage(target.value);
	};

	const handleClose = () => {
		setMessage('');
		setVisible(false);
		setDisabled(false);
	};

	const handleSelectRerunCheckbox = (
		event: ChangeEvent<HTMLInputElement>,
		dataId: string
	) => {
		if (props.setBatch)
			props.setBatch((prevValue) => {
				if (prevValue) {
					const target = event.target as HTMLInputElement;

					const samples = prevValue.samples.map((sample) => {
						if (sample._id.$oid === dataId)
							return {
								...sample,
								[target.name]: !sample[target.name],
							};

						return sample;
					});

					return {
						...prevValue,
						samples,
					};
				}

				return prevValue;
			});
	};

	return (
		<Modal
			onClose={handleClose}
			visible={visible && Boolean(props.batch && props.action)}
			customWidth={props.action === 'remove' ? 'max-w-md' : 'max-w-6xl'}
			title={
				props.action === 'remove' ? 'Confirm removal' : 'Confirm reruns'
			}
			description="This action is irreversible. Review the samples and confirm the action."
		>
			{props.batch ? (
				<Fragment>
					{props.action === 'rerun' && (
						<Alert
							type="info"
							heading="Info"
							className="mb-4"
							description={
								<span>
									If the sample to rerun won't meet the
									turnaround time, check off{' '}
									<span className="italic font-semibold">
										Send rerun email notification
									</span>
									, and an email notification will be sent to
									the customer. A list of the specimen IDs
									will be sent along with the message to the
									provider. They do not need to be included in
									the message.
								</span>
							}
						/>
					)}

					<DescriptionList>
						<DescriptionItem
							customColSpan="sm:col-span-3"
							term={
								props.action === 'rerun'
									? 'Samples to rerun (Pangea IDs)'
									: 'Samples to remove (Pangea IDs)'
							}
						>
							{props.action === 'rerun' ? (
								<Table>
									<TableHead>
										<HeaderCell>Priority Level</HeaderCell>
										<HeaderCell>Received Date</HeaderCell>
										<HeaderCell>Pangea ID</HeaderCell>
										<HeaderCell>
											Remove from batch
										</HeaderCell>
										<HeaderCell>
											Send rerun email notification
										</HeaderCell>
									</TableHead>
									<TableBody>
										{props.batch.samples.map(
											(datum, index) => {
												if (datum.checked)
													return (
														<TableRow
															key={index}
															index={index}
														>
															<DataCell>
																{getLabelFromValue(
																	datum.sample
																		.priority,
																	ORGANIZATIONS.priorityLevels
																)}
															</DataCell>
															<DataCell>
																{formatDate(
																	datum.sample
																		.sample_received_date,
																	true
																)}
															</DataCell>
															<DataCell>
																{
																	datum.sample
																		.pangea_id
																}
															</DataCell>
															<DataCell>
																<CheckboxField
																	name="rerun_batch_remove"
																	value={datum[
																		'rerun_batch_remove'
																	].toString()}
																	handleInputChange={(
																		event
																	) =>
																		handleSelectRerunCheckbox(
																			event,
																			datum
																				._id
																				.$oid
																		)
																	}
																/>
															</DataCell>
															<DataCell>
																<CheckboxField
																	name="send_rerun_email_notification"
																	value={datum[
																		'send_rerun_email_notification'
																	].toString()}
																	handleInputChange={(
																		event
																	) =>
																		handleSelectRerunCheckbox(
																			event,
																			datum
																				._id
																				.$oid
																		)
																	}
																/>
															</DataCell>
														</TableRow>
													);

												return null;
											}
										)}
									</TableBody>
								</Table>
							) : (
								<ul className="list-disc list-inside">
									{props.batch.samples.map((data, index) => {
										return data.checked ? (
											<li key={index}>
												{data.sample.pangea_id}
											</li>
										) : null;
									})}
								</ul>
							)}
						</DescriptionItem>
					</DescriptionList>

					<Form className="mt-4">
						<FormGroup>
							{props.action === 'remove' ? (
								<div className="sm:col-span-6">
									<GenericField
										required
										type="text"
										name="message"
										value={message}
										label={
											<span>
												Enter{' '}
												<span className="italic">
													Remove
												</span>{' '}
												to confirm
											</span>
										}
										placeholder="Remove"
										handleInputChange={handleChange}
									/>
								</div>
							) : (
								// props.batch.samples.filter(
								// 	(x_data) =>
								// 		x_data.send_rerun_email_notification
								// ).length > 0 && (
								<div className="sm:col-span-6">
									<TextareaField
										required
										name="message"
										value={message}
										label="Rerun reason"
										handleInputChange={handleChange}
										placeholder="e.g., Please provide the issue for the rerun."
										description={
											<span>
												For all specimens where{' '}
												<span className="italic">
													Send rerun email
													notification?
												</span>{' '}
												is set to{' '}
												<span className="italic">
													Yes
												</span>
												, the provided rerun reason will
												be included in the email
												notification sent to the
												customer.
											</span>
										}
									/>
								</div>
								// )
							)}
						</FormGroup>
					</Form>

					<FormFooter>
						<Button
							type="button"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
							onClick={() => handleAction(props.action)}
							text={
								props.action === 'remove' ? 'Remove' : 'Rerun'
							}
						/>
					</FormFooter>
				</Fragment>
			) : (
				<LoadingBox />
			)}
		</Modal>
	);
}

interface BatchRecordTableProps {
	batch: Batch;
	mainCheckbox: boolean;
	handleSelectRow: (sampleId: string) => void;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	handleSelectAll: ChangeEventHandler<HTMLInputElement>;
}

export function BatchRecordTable(props: BatchRecordTableProps) {
	const navigate = useNavigate();

	return (
		<Table>
			<TableHead>
				<HeaderCell customWidth="w-16">
					<div className="flex items-center justify-center">
						<input
							type="checkbox"
							checked={props.mainCheckbox}
							onChange={props.handleSelectAll}
							className="w-5 h-5 cursor-pointer border border-gray-300 rounded-md"
						/>
					</div>
				</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>Status</HeaderCell>
				<HeaderCell>Accessioning completed?</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection kit ID</HeaderCell>
				<HeaderCell>Received Date</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
				<HeaderCell>Stable by date</HeaderCell>
				<HeaderCell>Patient first name</HeaderCell>
				<HeaderCell>Patient last name</HeaderCell>
				<HeaderCell>Customer</HeaderCell>
			</TableHead>
			<TableBody>
				{props.batch.samples.length ? (
					props.batch.samples.map((datum, index) => {
						return (
							<TableRow key={index} index={index}>
								<DataCell>
									<div className="flex items-center justify-center">
										<input
											type="checkbox"
											checked={datum.checked}
											onChange={() =>
												props.handleSelectRow(
													datum._id.$oid
												)
											}
											className="w-5 h-5 cursor-pointer border border-gray-300 rounded-md"
										/>
									</div>
								</DataCell>
								<DataCell>
									{getLabelFromValue(
										datum.sample.priority,
										ORGANIZATIONS.priorityLevels
									)}
								</DataCell>
								<DataCell>
									{getLabelFromValue(
										datum.sample.status,
										getSampleStatuses()
									)}
								</DataCell>
								<DataCell>
									{getLabelFromValue(
										datum.metadata.accessioning.completed.value.toString(),
										FORMS.yes_no_options
									)}
								</DataCell>
								<DataCell>
									<ArrowLinkButton
										text={datum.sample.pangea_id}
										onClick={() =>
											navigate(
												`/cls/view/samples/${datum._id.$oid}`
											)
										}
									/>
								</DataCell>
								<DataCell>{datum.sample.specimen_id}</DataCell>
								<DataCell>
									{datum.sample.collection_kit_id}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.sample.sample_received_date,
										true
									)}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.sample.sample_collection_date
									)}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.sample.misc.sample_stability
											.stable_until &&
											datum.sample.misc.sample_stability
												.stable_until.$date
									)}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.first_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.last_name
										: null}
								</DataCell>
								<DataCell>
									{`${datum.customer.first_name} ${datum.customer.last_name} - ${datum.organization.name}`}
								</DataCell>
							</TableRow>
						);
					})
				) : (
					<EmptyTable
						heading="No samples"
						Icon="FingerPrintIcon"
						description="Start by adding samples to the batch"
					/>
				)}
			</TableBody>
		</Table>
	);
}

interface RemoveSampleModalProps {
	data: (ClinicData | NonClinicData) | undefined;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function RemoveSampleModal(props: RemoveSampleModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();
	const { batchId } = useParams();
	const { visible, setVisible } = props.visible;

	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (!axios || disabled || !props.data) return;
		setDisabled(true);

		toast.loading('Removing...', { id: toastId });

		try {
			await (
				await axios
			).patch(
				`/api/cls/batches/${batchId}/remove-sample/${props.data._id.$oid}`,
				{ user }
			);

			setVisible(false);
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
		}

		toast.dismiss(toastId);
	};

	return (
		<Modal
			title="Remove sample"
			customWidth="max-w-lg"
			onClose={() => setVisible(false)}
			visible={visible && Boolean(props.data)}
			description="This action is irreversible. Review the sample info and confirm removal."
		>
			{props.data ? (
				<Fragment>
					<DescriptionList>
						<DescriptionItem
							term="Pangea ID"
							details={props.data.sample.pangea_id}
						/>
						<DescriptionItem
							term="Specimen ID"
							details={props.data.sample.specimen_id}
						/>
						<DescriptionItem
							term="Customer"
							details={props.data.customer.first_name.concat(
								' ',
								props.data.customer.last_name
							)}
						/>
						<DescriptionItem
							term="Organization"
							details={props.data.organization.name}
						/>

						{props.data.type === 'clinic' && (
							<Fragment>
								<DescriptionItem
									term="Patient name"
									details={
										props.data.patient
											? capitalize(
													props.data.patient.first_name.concat(
														' ',
														props.data.patient
															.last_name
													)
											  )
											: null
									}
								/>
								<DescriptionItem
									term="Patient date of birth"
									details={
										props.data.patient
											? props.data.patient.date_of_birth
											: null
									}
								/>
							</Fragment>
						)}
					</DescriptionList>
					<FormFooter>
						<Button
							type="button"
							text="Remove"
							tier="tertiary"
							disabled={disabled}
							Icon="ArchiveBoxIcon"
							onClick={handleSubmit}
						/>
					</FormFooter>
				</Fragment>
			) : (
				<LoadingBox />
			)}
		</Modal>
	);
}

interface EditQCModalProps {
	batch: Batch | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

interface QCFormValues {
	['qc.passed']: string;
	['qc.instrument_id']: string;
	['qc.positive_sample.value']: string;
	['qc.positive_sample.result']: string;
	['qc.negative_sample.value']: string;
	['qc.negative_sample.result']: string;
	['qc.extraction_kit.kit_name']: string;
	['qc.extraction_kit.lot_number']: string;
	['qc.extraction_kit.expiration_date']: string;
	['qc.pcr_kit.kit_name']: string;
	['qc.pcr_kit.lot_number']: string;
	['qc.pcr_kit.expiration_date']: string;
	['qc.dcc_kit.applicable']: string;
	['qc.dcc_kit.kit_name']: string;
	['qc.dcc_kit.lot_number']: string;
	['qc.dcc_kit.expiration_date']: string;
}

export function EditQCModal(props: EditQCModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();
	const { batchId } = useParams();
	const { visible, setVisible } = props.visible;

	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] = useState<QCFormValues>({
		'qc.passed': '',
		'qc.instrument_id': '',
		'qc.positive_sample.value': '',
		'qc.positive_sample.result': '',
		'qc.negative_sample.value': '',
		'qc.negative_sample.result': '',
		'qc.extraction_kit.kit_name': '',
		'qc.extraction_kit.lot_number': '',
		'qc.extraction_kit.expiration_date': '',
		'qc.pcr_kit.kit_name': '',
		'qc.pcr_kit.lot_number': '',
		'qc.pcr_kit.expiration_date': '',
		'qc.dcc_kit.applicable': '',
		'qc.dcc_kit.kit_name': '',
		'qc.dcc_kit.lot_number': '',
		'qc.dcc_kit.expiration_date': '',
	});

	useEffect(() => {
		if (props.batch)
			setFormValues({
				'qc.instrument_id': props.batch.qc.instrument_id,
				'qc.passed': props.batch.qc.passed,
				'qc.positive_sample.value':
					props.batch.qc.positive_sample.value,
				'qc.positive_sample.result':
					props.batch.qc.positive_sample.result,
				'qc.negative_sample.value':
					props.batch.qc.negative_sample.value,
				'qc.negative_sample.result':
					props.batch.qc.negative_sample.result,
				'qc.extraction_kit.kit_name':
					props.batch.qc.extraction_kit.kit_name || '',
				'qc.extraction_kit.lot_number':
					props.batch.qc.extraction_kit.lot_number,
				'qc.extraction_kit.expiration_date':
					props.batch.qc.extraction_kit.expiration_date,
				'qc.pcr_kit.kit_name': props.batch.qc.pcr_kit.kit_name || '',
				'qc.pcr_kit.lot_number': props.batch.qc.pcr_kit.lot_number,
				'qc.pcr_kit.expiration_date':
					props.batch.qc.pcr_kit.expiration_date,
				'qc.dcc_kit.applicable': props.batch.qc.dcc_kit.applicable
					? 'true'
					: 'false',
				'qc.dcc_kit.kit_name': props.batch.qc.dcc_kit.kit_name || '',
				'qc.dcc_kit.lot_number': props.batch.qc.dcc_kit.lot_number,
				'qc.dcc_kit.expiration_date':
					props.batch.qc.dcc_kit.expiration_date,
			});
	}, [props.batch]);

	const handleUpdate = async () => {
		if (disabled || !axios || !props.batch) return;

		setDisabled(true);

		toast.loading('Updating...', { id: toastId });

		try {
			const normalizedFormValues = normalizeFormValues(formValues);

			await (
				await axios
			).patch(`/api/cls/batch/${batchId}/qc`, {
				user,
				form_data: normalizedFormValues,
			});

			handleClose();
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}

		toast.dismiss(toastId);
	};

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setFormValues((prevValue) => ({
				...prevValue,
				[target.name]: target.value,
			}));
	};

	const handleClose = () => {
		setVisible(false);
		setDisabled(false);
	};

	// Handle Select Changes
	const [selections, setSelections] = useState({
		'qc.extraction_kit.kit_name': '',
		'qc.pcr_kit.kit_name': '',
		'qc.dcc_kit.kit_name': '',
	});

	useEffect(() => {
		if (formValues) {
			// Extraction Kit
			if (
				formValues['qc.extraction_kit.kit_name'] !== '' &&
				!ExtractionKitOptions.map(
					(option: Option) => option.value
				).includes(formValues['qc.extraction_kit.kit_name'])
			) {
				setSelections((prevValue) => ({
					...prevValue,
					'qc.extraction_kit.kit_name': 'other',
				}));
			} else {
				setSelections((prevValue) => ({
					...prevValue,
					'qc.extraction_kit.kit_name':
						formValues['qc.extraction_kit.kit_name'],
				}));
			}

			// PCR Kit
			if (
				formValues['qc.pcr_kit.kit_name'] !== '' &&
				!PCRKitOptions.map((option: Option) => option.value).includes(
					formValues['qc.pcr_kit.kit_name']
				)
			) {
				setSelections((prevValue) => ({
					...prevValue,
					'qc.pcr_kit.kit_name': 'other',
				}));
			} else {
				setSelections((prevValue) => ({
					...prevValue,
					'qc.pcr_kit.kit_name': formValues['qc.pcr_kit.kit_name'],
				}));
			}

			// DCC Kit
			if (formValues['qc.dcc_kit.applicable']) {
				if (
					formValues['qc.dcc_kit.kit_name'] !== '' &&
					!DCCKitOptions.map(
						(option: Option) => option.value
					).includes(formValues['qc.dcc_kit.kit_name'])
				) {
					setSelections((prevValue) => ({
						...prevValue,
						'qc.dcc_kit.kit_name': 'other',
					}));
				} else {
					setSelections((prevValue) => ({
						...prevValue,
						'qc.dcc_kit.kit_name':
							formValues['qc.dcc_kit.kit_name'],
					}));
				}
			}
		}
	}, [formValues]);

	const handleSelectionChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setSelections((prevValue) => ({
				...prevValue,
				[target.name]: target.value,
			}));

			setFormValues((prevValues: QCFormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						[target.name]:
							target.value === 'other' ? '' : target.value,
					};
				}

				return prevValues;
			});
		}
	};

	return (
		<Modal
			onClose={handleClose}
			customWidth="max-w-2xl"
			title="Update QC details"
			visible={visible && Boolean(props.batch)}
		>
			{props.batch ? (
				<Fragment>
					<Form className="mt-4">
						<FormGroup heading="Instrument Specification">
							<div className="sm:col-span-3">
								<GenericField
									required
									type="text"
									name="qc.instrument_id"
									label="Instrument ID"
									handleInputChange={handleChange}
									placeholder="e.g., 785XS11212"
									value={formValues['qc.instrument_id'] || ''}
								/>
							</div>
						</FormGroup>

						<hr />

						<FormGroup heading="Bladder CARE™ Positive Sample QC">
							{(() => {
								const invalidValue =
									formValues['qc.positive_sample.value'] &&
									(parseInt(
										formValues['qc.positive_sample.value']
									) <= 10 ||
										parseInt(
											formValues[
												'qc.positive_sample.value'
											]
										) < 0);

								const invalidResult =
									formValues['qc.positive_sample.result'] ===
									'negative';

								if (invalidValue || invalidResult) {
									return (
										<div className="sm:col-span-6 space-y-2">
											{invalidValue && (
												<Alert
													type="warning"
													heading="Warning"
													description="The value of the positive sample should be greater than 10. Please review the value before proceeding."
												/>
											)}
											{invalidResult && (
												<Alert
													type="warning"
													heading="Warning"
													description="The result of the positive sample is negative. Please review the result."
												/>
											)}
										</div>
									);
								}

								return null;
							})()}

							<div className="sm:col-span-3">
								<GenericField
									required
									type="text"
									name="qc.positive_sample.value"
									label="Positive sample value"
									handleInputChange={handleChange}
									placeholder="e.g., 2.5"
									value={
										formValues[
											'qc.positive_sample.value'
										] || ''
									}
								/>
							</div>
							<div className="sm:col-span-3">
								<SelectField
									required
									name="qc.positive_sample.result"
									label="Positive sample result"
									handleSelect={handleChange}
									options={BATCHES.qc_sample_result_options}
									value={
										formValues[
											'qc.positive_sample.result'
										] || ''
									}
								/>
							</div>
						</FormGroup>

						<hr />

						<FormGroup heading="Bladder CARE™ Negative Sample QC">
							{(() => {
								const invalidValue =
									formValues['qc.negative_sample.value'] &&
									(parseInt(
										formValues['qc.negative_sample.value']
									) >= 2.5 ||
										parseInt(
											formValues[
												'qc.negative_sample.value'
											]
										) < 0);

								const invalidResult =
									formValues['qc.negative_sample.result'] ===
									'positive';

								if (invalidValue || invalidResult) {
									return (
										<div className="sm:col-span-6 space-y-2">
											{invalidValue && (
												<Alert
													type="warning"
													heading="Warning"
													description="The value of the negative sample should be less than 2.5. Please review the value before proceeding."
												/>
											)}
											{invalidResult && (
												<Alert
													type="warning"
													heading="Warning"
													description="The result of the negative sample is positive. Please review the result."
												/>
											)}
										</div>
									);
								}

								return null;
							})()}
							<div className="sm:col-span-3">
								<GenericField
									required
									type="text"
									name="qc.negative_sample.value"
									label="Negative sample value"
									handleInputChange={handleChange}
									placeholder="e.g., 2.5"
									value={
										formValues[
											'qc.negative_sample.value'
										] || ''
									}
								/>
							</div>
							<div className="sm:col-span-3">
								<SelectField
									required
									name="qc.negative_sample.result"
									label="Negative sample result"
									handleSelect={handleChange}
									options={BATCHES.qc_sample_result_options}
									value={
										formValues[
											'qc.negative_sample.result'
										] || ''
									}
								/>
							</div>
						</FormGroup>

						<hr />

						<FormGroup heading="Extraction Kit">
							<div className="sm:col-span-3">
								<SelectField
									required
									name="qc.extraction_kit.kit_name"
									label="Kit name"
									handleSelect={handleSelectionChange}
									options={ExtractionKitOptions}
									value={
										selections['qc.extraction_kit.kit_name']
									}
								/>
							</div>
							{selections['qc.extraction_kit.kit_name'] ===
							'other' ? (
								<div className="sm:col-span-3">
									<GenericField
										required
										type="text"
										name="qc.extraction_kit.kit_name"
										label="If other, please specify"
										handleInputChange={handleChange}
										placeholder="e.g., Extraction Kit"
										value={
											formValues[
												'qc.extraction_kit.kit_name'
											] || ''
										}
									/>
								</div>
							) : (
								<div className="sm:col-span-3"></div>
							)}
							<div className="sm:col-span-3">
								<GenericField
									required
									type="text"
									name="qc.extraction_kit.lot_number"
									label="Lot #"
									handleInputChange={handleChange}
									placeholder="e.g., 12345678"
									value={
										formValues[
											'qc.extraction_kit.lot_number'
										] || ''
									}
								/>
							</div>
							<div className="sm:col-span-3">
								<DateField
									required
									noMax={true}
									label="Expiration Date"
									name="qc.extraction_kit.expiration_date"
									handleInputChange={handleChange}
									value={
										formValues[
											'qc.extraction_kit.expiration_date'
										] || ''
									}
								/>
							</div>
						</FormGroup>

						<hr />

						<FormGroup heading="PCR Kit">
							<div className="sm:col-span-3">
								<SelectField
									required
									name="qc.pcr_kit.kit_name"
									label="Kit name"
									handleSelect={handleSelectionChange}
									options={PCRKitOptions}
									value={selections['qc.pcr_kit.kit_name']}
								/>
							</div>
							{selections['qc.pcr_kit.kit_name'] === 'other' ? (
								<div className="sm:col-span-3">
									<GenericField
										required
										type="text"
										name="qc.pcr_kit.kit_name"
										label="If other, please specify"
										handleInputChange={handleChange}
										placeholder="e.g., PCR Kit"
										value={
											formValues['qc.pcr_kit.kit_name'] ||
											''
										}
									/>
								</div>
							) : (
								<div className="sm:col-span-3"></div>
							)}
							<div className="sm:col-span-3">
								<GenericField
									required
									type="text"
									name="qc.pcr_kit.lot_number"
									label="Lot #"
									handleInputChange={handleChange}
									placeholder="e.g., 12345678"
									value={
										formValues['qc.pcr_kit.lot_number'] ||
										''
									}
								/>
							</div>
							<div className="sm:col-span-3">
								<DateField
									required
									label="Expiration Date"
									noMax={true}
									name="qc.pcr_kit.expiration_date"
									handleInputChange={handleChange}
									value={
										formValues[
											'qc.pcr_kit.expiration_date'
										] || ''
									}
								/>
							</div>
						</FormGroup>

						<hr />

						<FormGroup heading="DCC Kit">
							<div className="sm:col-span-3">
								<SelectField
									required
									name="qc.dcc_kit.applicable"
									label="Is DCC applicable?"
									handleSelect={handleChange}
									options={FORMS.yes_no_options}
									value={
										formValues['qc.dcc_kit.applicable'] ||
										''
									}
								/>
							</div>
							<div className="sm:col-span-3"></div>
							{formValues['qc.dcc_kit.applicable'] === 'true' && (
								<Fragment>
									<div className="sm:col-span-3">
										<SelectField
											required
											name="qc.dcc_kit.kit_name"
											label="Kit name"
											handleSelect={handleSelectionChange}
											options={DCCKitOptions}
											value={
												selections[
													'qc.dcc_kit.kit_name'
												]
											}
										/>
									</div>
									{selections['qc.dcc_kit.kit_name'] ===
									'other' ? (
										<div className="sm:col-span-3">
											<GenericField
												required
												type="text"
												name="qc.dcc_kit.kit_name"
												label="If other, please specify"
												handleInputChange={handleChange}
												placeholder="e.g., DCC Kit"
												value={
													formValues[
														'qc.dcc_kit.kit_name'
													] || ''
												}
											/>
										</div>
									) : (
										<div className="sm:col-span-3"></div>
									)}
									<div className="sm:col-span-3">
										<GenericField
											required
											type="text"
											name="qc.dcc_kit.lot_number"
											label="Lot #"
											handleInputChange={handleChange}
											placeholder="e.g., 12345678"
											value={
												formValues[
													'qc.dcc_kit.lot_number'
												] || ''
											}
										/>
									</div>
									<div className="sm:col-span-3">
										<DateField
											required
											label="Expiration Date"
											noMax={true}
											name="qc.dcc_kit.expiration_date"
											handleInputChange={handleChange}
											value={
												formValues[
													'qc.dcc_kit.expiration_date'
												] || ''
											}
										/>
									</div>
								</Fragment>
							)}
						</FormGroup>

						<hr />

						<FormGroup>
							{(() => {
								const invalidPositiveValue =
									formValues['qc.positive_sample.value'] &&
									(parseInt(
										formValues['qc.positive_sample.value']
									) <= 10 ||
										parseInt(
											formValues[
												'qc.positive_sample.value'
											]
										) < 0);

								const invalidPositiveResult =
									formValues['qc.positive_sample.result'] ===
									'negative';

								const invalidNegativeValue =
									formValues['qc.negative_sample.value'] &&
									(parseInt(
										formValues['qc.negative_sample.value']
									) >= 2.5 ||
										parseInt(
											formValues[
												'qc.negative_sample.value'
											]
										) < 0);

								const invalidNegativeResult =
									formValues['qc.negative_sample.result'] ===
									'positive';

								if (
									(invalidPositiveValue ||
										invalidPositiveResult ||
										invalidNegativeValue ||
										invalidNegativeResult) &&
									formValues['qc.passed'] === 'acceptable'
								) {
									return (
										<div className="sm:col-span-6 space-y-2">
											<Alert
												type="warning"
												heading="Warning"
												description="One or more values or results are invalid. Please review the form carefully before submitting the changes."
											/>
										</div>
									);
								} else if (
									!(
										invalidPositiveValue ||
										invalidPositiveResult ||
										invalidNegativeValue ||
										invalidNegativeResult
									) &&
									formValues['qc.passed'] === 'failed'
								) {
									return (
										<div className="sm:col-span-6 space-y-2">
											<Alert
												type="warning"
												heading="Warning"
												description="All values and results are valid but the selected QC status is failed. Please review the form carefully before submitting the changes."
											/>
										</div>
									);
								}

								return null;
							})()}
							<div className="sm:col-span-3">
								<SelectField
									required
									name="qc.passed"
									label="QC Status"
									handleSelect={handleChange}
									options={BATCHES.qc_status_options}
									value={formValues['qc.passed'] || ''}
								/>
							</div>
						</FormGroup>
					</Form>
					<FormFooter>
						<Button
							text="Update"
							type="button"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
							onClick={handleUpdate}
						/>
					</FormFooter>
				</Fragment>
			) : (
				<LoadingBox />
			)}
		</Modal>
	);
}
