import { Dispatch, SetStateAction } from 'react';

import {
	FORMS,
	getLabelFromValue,
	CustomerBillingMethod,
} from '@pangea-lis-apps/utils';
import {
	Button,
	EmptyBox,
	TextButton,
	ContentSection,
	DescriptionList,
	DescriptionItem,
} from '@pangea-lis-apps/ui';
import { BillingMethodFormValues } from '../../../pages/create-member/add-billing-method-modal';

interface CustomerBillingMethodsProps {
	billingMethods: (CustomerBillingMethod | BillingMethodFormValues)[];
	setBillingModalState: Dispatch<
		SetStateAction<{
			updateId: string;
			visible: boolean;
			action: 'create' | 'update' | string;
		}>
	>;
}

export function CustomerBillingMethods({
	billingMethods,
	setBillingModalState,
}: CustomerBillingMethodsProps) {
	return (
		<ContentSection
			heading="Billing Methods"
			headingAction={
				<Button
					type="button"
					tier="tertiary"
					Icon="PlusIcon"
					text="Add billing method"
					onClick={() =>
						setBillingModalState((prevValue) => ({
							...prevValue,
							action: 'create',
							visible: true,
						}))
					}
				/>
			}
		>
			{billingMethods.length > 0 ? (
				<ul className="list-none space-y-6">
					{billingMethods.map((method, index) => (
						<li
							key={index}
							className="bg-gray-100 border border-gray-200 space-y-6 rounded-xl p-6"
						>
							<DescriptionList
								heading="Client information"
								headingAction={
									<TextButton
										text="Update"
										color="blue"
										type="submit"
										onClick={() => {
											setBillingModalState({
												updateId:
													'id' in method
														? method.id
														: method._id.$oid,
												action: 'update',
												visible: true,
											});
										}}
									/>
								}
							>
								<DescriptionItem
									term="Name"
									details={method.client_name}
								/>
								<DescriptionItem
									term="Institution or Company"
									details={method.client_institution}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Address"
									customColSpan="sm:col-span-2"
									details={method.client_address}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Address Line 2"
									customColSpan="sm:col-span-2"
									details={method.client_address2}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="City"
									details={method.client_city}
								/>
								<DescriptionItem
									term="State"
									details={getLabelFromValue(
										method.client_state,
										FORMS.states
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="ZIP Code"
									details={method.client_zip_code}
								/>
								<DescriptionItem
									term="Country"
									details={getLabelFromValue(
										method.client_country,
										FORMS.countries
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Phone Number"
									details={method.client_phone_number}
								/>
								<DescriptionItem
									term="Fax Number"
									details={method.client_fax_number}
								/>
								<DescriptionItem
									term="Email Address"
									details={method.client_email_address}
								/>
							</DescriptionList>

							<hr />

							<DescriptionList heading="Client's billing contact information">
								<DescriptionItem
									term="Name"
									details={method.billing_contact_name}
								/>
								<DescriptionItem
									term="Institution or Company"
									details={method.billing_contact_institution}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Address"
									customColSpan="sm:col-span-2"
									details={method.billing_contact_address}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Address Line 2"
									customColSpan="sm:col-span-2"
									details={method.billing_contact_address2}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="City"
									details={method.billing_contact_city}
								/>
								<DescriptionItem
									term="State"
									details={getLabelFromValue(
										method.billing_contact_state,
										FORMS.states
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="ZIP Code"
									details={method.billing_contact_zip_code}
								/>
								<DescriptionItem
									term="Country"
									details={getLabelFromValue(
										method.billing_contact_country,
										FORMS.countries
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Phone Number"
									details={
										method.billing_contact_phone_number
									}
								/>
								<DescriptionItem
									term="Fax Number"
									details={method.billing_contact_fax_number}
								/>
								<DescriptionItem
									term="Email Address"
									details={
										method.billing_contact_email_address
									}
								/>
							</DescriptionList>
						</li>
					))}
				</ul>
			) : (
				<EmptyBox Icon="NoSymbolIcon" heading="No billing methods" />
			)}
		</ContentSection>
	);
}
